import React, {useContext} from "react";
import Consent from "../assets/consent.png";
import { navBarContext } from "./context/context";
const Pitchdeck = () => {
    const { navBarHeight } = useContext(navBarContext);
  return (
    <div       id="newMatch__"
    style={{
      marginTop: navBarHeight + 30,
      marginBottom: navBarHeight + 10,
      width: "100%",
      minWidth: "366px",
      padding: "2%",
    }}
    className="flex flex-col justify-center items-center">
      <h1 className="text-2xl mb-5">What is a pitch deck?</h1>

      <p className="w-1/2">
        A pitch deck is a concise and visually compelling presentation that
        provides an overview of a startup's business, strategy, and potential
        for growth. Typically consisting of a series of slides, a pitch deck is
        designed to effectively communicate key information to potential
        investors, partners, or stakeholders. It serves as a powerful tool for
        startups to present their business idea, showcase their unique value
        proposition, highlight market opportunities, and demonstrate the team's
        capability to execute the proposed plan. 
        
        <br/>
        <br/>
        The primary goal of a pitch
        deck is to captivate the audience's attention, convey the startup's
        vision, and generate interest and support. It covers essential aspects
        such as the problem the startup is addressing, the solution it offers,
        the market size and potential, the competitive landscape, revenue model,
        and the team's qualifications. A well-crafted pitch deck not only
        communicates the business concept clearly but also instills confidence
        in the audience about the startup's potential for success.
      </p>
    </div>
  );
};

export default Pitchdeck;
