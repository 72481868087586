import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CryingIcon from "../assets/unicorn-crying.png";
import { navBarContext, authContext } from "./context/context";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { query, collection, db, where, getDocs, addDoc, getDoc, updateDoc } from "../firebase/firebaseApp";
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { getAuth, deleteUser } from "firebase/auth";
import { getDatabase, ref, remove } from 'firebase/database';
import { Loading } from "@peterfosso/prairi-components";

const Remove = () => {
    const { navBarHeight } = useContext(navBarContext);
    const { currentUser } = useContext(authContext);
    const auth = getAuth();
    const user = auth.currentUser;
    let navigate = useNavigate();
    const [docId, setDocId] = useState(null);
    const [popUp, setPopUp] = useState(false)
    const [reason, setReason] = useState("");
    const [otherReason, setOtherReason] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [currentUserData, setCurrentUserData] = useState(null)
    // console.log("npm start");
    // console.log(docId);

    useEffect(() => {
        if (currentUser) {
            setDocId(currentUser.uid);
        }
    }, [currentUser]);


      const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    if (!currentUser && dataFetched) {
      redirectToLogin();
    }
  }, [currentUser]);

    const getDataFromDb = async () => {
        try {
            // Get user data from email
            const { email } = currentUser;
            const q = query(collection(db, "users"), where("email", "==", email));
            const querySnapshot = await getDocs(q);
            let userData;
            let userId;
            querySnapshot.forEach((doc) => {
                userData = doc.data();
                userId = doc.id;
            });
            setCurrentUserData(userData)
            return { userData, userId };
        } catch (error) {
            // console.log(error);
        }
    };

    const fetchData = async () => {
        try {
            if (currentUser) {
                // Assuming currentUser is defined in your component
                await getDataFromDb(); // Pass the UID from currentUser
                setDataFetched(true)

            }
        } catch (error) {
            //console.log('error fetching data');
        }
    }

    useEffect(() => {
        fetchData();
    }, [currentUser]);

    const handleRemove = async (e) => {
        if (user) {
            try {
                const firestore = getFirestore();
                const { uid } = user; // Use `user` instead of `currentUser`
                const q = query(collection(db, "users"), where("uid", "==", uid));
                const querySnapshot = await getDocs(q);
                let userId;
                // Delete the user document from the "users" collection
                querySnapshot.forEach((doc) => {
                    userId = doc.id;
                });
                const userRef = doc(db, "users", userId);
                if (reason !== "Other") {
                    await addDoc(collection(db, "deleteReasons"), {
                        reason: reason,
                    })
                }
                else {
                    await addDoc(collection(db, "deleteReasons"), {
                        reason: otherReason,
                    })
                }
                await deleteUser(user);
                await deleteDoc(userRef);
                //  console.log("User deleted from db");
                

                //  console.log("User deleted from auth");
                navigate("/removeconfirmation")
            } catch (error) {
                // console.log("User not deleted :(", error);
            }
        } else {
            //  console.log("No user signed in");
        }
    };

    const handlePauseDataProfile = async () => {
        const { userData } = await getDataFromDb();
        if (userData.paused) {
            navigate("/reactivate");
        } else {
            navigate("/matchpause");
        }
    };

    const WhiteRadio = withStyles({
        root: {
            color: "white",
            "&$checked": {
                color: "green",
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    
    if (!dataFetched){
        return (<div className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
        style={{
          marginTop: navBarHeight + 30,
      
        }}><Loading/></div>)
      }


    return (
        <div
            className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
            style={{
                marginTop: navBarHeight + 4, backgroundColor: "#1e1e1e",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
                borderRadius: "60px"
            }}
        >



            {dataFetched && currentUserData.category === "startup" && <div>
                <div className="w-3/12 mx-auto mt-8 mb-4">
                    <img className="w-3/4" src={CryingIcon} alt="Settings icon" />
                </div>
                <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black md:text-xl my-4" >
                    Remove/Delete your match profile{" "}
                </h1>
                <h2 className="font-bold text-center w-full text-customWhite md:text-xl my-4" >
                    Reason I wish to delete my Data Profile and account:
                </h2>
                <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                            name="radio-buttons-group"
                            className={`pt-3`}
                        >
                            <FormControlLabel
                                value="Not enough value"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        I/We don't see enough value to be on your service.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Received funding"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        We received our funding on PRAIRI (or elsewhere) and don't envision ever needing a future round of funding.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Not enough matches"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        Too few or not enough quality matches.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Other"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        Other reason:
                                        <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3  mx-0.5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" We just recived our funding />
                                    </span>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    <div className="my-2 mt-8 flex-1/3 gap-1 justify-between">
                        {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}

                        <button className="flex-1/3 bg-redPrairi text-black p-2 rounded-md  outline outline-2     outline-offset-2 "
                            onClick={() => setPopUp(true)}
                        >
                            Delete My Data Profile & Account
                        </button>
                        <button
                            onClick={handlePauseDataProfile} className=" mx-7 flex-auto justify-items-end outline outline-2  p-2 rounded-md outline-offset-2">Back</button>
                    </div>
                </div>
            </div>}

            {dataFetched && currentUserData.category === "investor" && <div>
                <div className="w-3/12 mx-auto mt-8 mb-4">
                    <img className="w-3/4" src={CryingIcon} alt="Settings icon" />
                </div>
                <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black md:text-xl my-4" >
                    Remove/Delete your match profile{" "}
                </h1>
                <h2 className="font-bold text-center w-full text-customWhite md:text-xl my-4" >
                    Reason I wish to delete my Data Profile and account:
                </h2>
                <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                            name="radio-buttons-group"
                            className={`pt-3`}
                        >
                            <FormControlLabel
                                value="No longer invests"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        I/We no longer invest in startups.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Not what they're looking for"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        The startups on PRAIRI aren't what I'm looking for.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Too many notifications"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        Too many notifications.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Not enough matches"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        Too few or not enough quality matches.
                                    </span>
                                }
                            />
                            <FormControlLabel
                                value="Other"
                                control={<WhiteRadio />}
                                label={
                                    <span>
                                        Other reason:
                                        <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3  mx-0.5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" We just recived our funding />
                                    </span>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    <div className="my-2 mt-8 flex-1/3 gap-1 justify-between">
                        {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}

                        <button className="flex-1/3 bg-redPrairi text-black p-2 rounded-md  outline outline-2     outline-offset-2 "
                            onClick={() => setPopUp(true)}
                        >
                            Delete My Data Profile & Account
                        </button>
                        <button
                            onClick={handlePauseDataProfile} className=" mx-7 flex-auto justify-items-end outline outline-2  p-2 rounded-md outline-offset-2">Back</button>
                    </div>
                </div>
            </div>}


            <Modal
                open={popUp}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    className="min-w-[250px] min-h-[270px]
                max-w-[300px]"
                    sx={{
                        background: "#1e1e1d",
                        color: "#f2f2f2",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "60%",
                        maxWidth: "450px",
                        maxHeight: "300px",
                        height: "70%",
                        borderRadius: "50px",
                        padding: "10px 17px 2px 15px",
                        opacity: "1",
                        fontSize: "16px",
                        border: "2px solid red"
                    }}

                ><img src={CryingIcon} className="w-1/6 h-auto mx-auto mb-2" alt=""></img>

                    <p className="mb-2 text-center text-customWhite">
                        Are you sure? This can't be undone. Do you know that you can instead just <button onClick={() => navigate("/matchpause")}><span className="underline text-customWhite">Pause your profile</span></button>?
                        This way it'll be ready when you seek your next round or even if you change companies
                    </p>


                    <div className="my-2 ml-8 flex-1/3 gap-1 justify-between">
                        {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}

                        <button className="flex-1/3 bg-redPrairi text-black p-2 rounded-md  outline outline-2     outline-offset-2 "
                            onClick={handleRemove}
                        >
                            Delete My Data Profile & Account
                        </button>
                        <button
                            onClick={() => setPopUp(false)} className=" mx-7 flex-auto justify-items-end outline outline-2  p-2 rounded-md outline-offset-2">Back</button>
                    </div>
                    <div
                        className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
                    >
                    </div>
                </Box>
            </Modal>



        </div>


    );
};


export default Remove;

