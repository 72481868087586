import { borderColor } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import BalanceIcon from "../../assets/balanceicon.png";
import How from "../../assets/how.png";
import NewMatch from "../../assets/newmatch.png";
import TransitionUpwardElement from "./TransitionUpwardElement";
import { Bullet } from "@peterfosso/prairi-components";
import PrairiTextLogo from "../../assets/prairitext.png"
import Logo from "../../assets/prairiwhite.png";
import { useMediaQuery } from "react-responsive";

const SectionTwoLandingPage = (props) => {
  let navigate = useNavigate();
  const { show } = props;
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const workProcessSummary = [
    {
      step: 1,
      detail: "Create your private Data Profile",
      image: How,
      alt: "How",
    },
    {
      step: 2,
      detail: "PRAIRI matches you based on your criteria",
      image: BalanceIcon,
      alt: "Balance Icon",
    },
    {
      step: 3,
      detail: "Receive matches on your mobile device",
      image: NewMatch,
      alt: "New Match",
    },
  ];

  return (
    <section
      className={`w-full  from-navStart to-navEnd text-customWhite ${
        show && "hidden"
      }`}
    >
      <div className="w-full flex flex-col justify-center ">
        <TransitionUpwardElement
          elementType="h1"
          elementClassNames="text-center  animate-transitionIn py-4 bg-[#595959] uppercase text-3xl md:text-3xl mb-8 font-bold text-white"
        >
          BENEFITS
        </TransitionUpwardElement>
        <div className="flex justify-center flex-col items-center my-8">
        <div className={`flex ${isMobile&&"flex-col"} justify-center text-xl lg:text-2xl mb-8 pb-8 w-[80%] items-center`}>
          <div className="flex flex-col">
            <div className="flex justify-center ">
            <button
              onClick={() => navigate("/startuphome")}
              id="landing-startup"
              className="py-3  px-12  rounded-full text-white  bg-startupBtn hover:bg-investorBtn mr-4"
            >
              Startup
            </button>
            </div>

            <div className="text-xl my-4">
              As a <span className="font-bold">Startup:</span>
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    Skip the endless searching for investors
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    No searching, investors come to{" "}
                    <span className="underline">you</span>
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    Save time, so you can instead focus on building a great
                    company
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    <span className="underline cursor-pointer" onClick={()=>navigate("/startuphome")}>Join now</span> and get a
                    lifetime membership!
                  </p>
                }
                tick
              />
            </div>
          </div>
          <div className="flex flex-col">
          <div className={`flex justify-center ${isMobile&&"mt-12"}`}>
            <button
              onClick={() => navigate("/invitation/investor")}
              id="landing-investor"
              className="py-3  px-12 text-white rounded-full bg-startupBtn hover:bg-investorBtn ml-4 "
            >
              Investor
            </button>
            </div>
            <div className="text-xl my-4">
              As an <span className="font-bold">Investor:</span>
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    Save time (no searching)
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    Receive unique startup opportunities
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    It's a big world. Why limit yourself to only startups that are in your network?
                  </p>
                }
                tick
              />
            </div>
            <div className="my-2">
              <Bullet
                text={
                  <p className="text-xl text-customWhite">
                    Don't miss out. 
                    <span className="underline ml-2 cursor-pointer" onClick={()=>navigate("/invitation/investor")}>Join PRAIRI now for FREE.</span></p>
                }
                tick
              />
            </div>
          </div>
        </div>
        <div className={`flex items-center ${isMobile && "flex-col"}`}>
          <div className="flex   items-center">
          <img src={Logo} className="h-[60px] mr-4"/>
          <img src={PrairiTextLogo} className="pt-2 h-[30px]"/>
          </div>
                <div className="text-xl text-white text-center italic ml-5 pt-2 pr-2">Helping founders and investors make their impact on the world - and <span className="font-bold">faster!</span></div>
        </div>
        </div>
      </div>
      <TransitionUpwardElement
        elementType="h1"
        elementClassNames="text-center animate-transitionIn py-4 bg-[#595959] uppercase text-3xl md:text-3xl mb-8 font-bold text-white"
      >
        How it works
      </TransitionUpwardElement>
      <div className="w-full animate-transitionIn flex justify-center">
        <ul className="lg:pr-20 px-4 text-xl lg:text-2xl flex justify-center space-x-8 flex-col items-start md:flex-row md:justify-center md:space-x-8">
          {workProcessSummary.map((process, index) => (
            <li
              key={process.detail}
              className="flex flex-col items-center text-center"
            >
              <div
                className={`w-12 h-12 my-2 flex bg-body items-center justify-center text-white border-2 ${
                  process.step === 1
                    ? "border-greenPrairi ml-2"
                    : process.step === 2
                    ? "border-yellowPrairi"
                    : "border-bluePrairi"
                } rounded-full`}
              >
                {process.step}
              </div>
              <p className="my-2">{process.detail}</p>
              {process.image && (
                <div className="w-full flex justify-center">
                  <img
                    className={`h-auto mt-4 justify-center text-center lg:w-2/3 ${
                      process.step === 2
                        ? "lg:w-1/3"
                        : process.step === 3
                        ? "pr-4 mr-4"
                        : "lg:w-1/2"
                    }`}
                    src={process.image}
                    alt={process.alt}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionTwoLandingPage;
