import React, { useState, useEffect, useContext } from "react";
import Switch from "react-switch";
import SupportLogo from "../../assets/support.png";
import { useNavigate } from "react-router-dom";
import ExpertiseLogo from "../../assets/expertise.png";
import { investorContext, authContext } from "../context/context";
import InvestorButton from "../common/investorButton/InvestorButton";
import Terms from "./../common/Terms";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  deleteField,
  addDoc,
} from "../../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions } from "../../utils";

const InvestorSupport = () => {
  const { setDataFunc, globalData } = useContext(investorContext);
  const supportData = globalData.formData.support;
  const { currentUser } = useContext(authContext);
  const [userRef, setUserRef] = useState([])
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState({
    support: supportData.support || "",
    expertise: supportData.expertise || "",
    contacts: supportData.contacts || "",
    other: supportData.other || "",
    different: supportData.different || "",
    remind: supportData.remind || "",
    error: "",
    loading: false,
    message: "",
    showTerms: false,
  });

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
      message: "Saving... (click progress bar if you wish to edit)",
    });

    try {
      const userSnap = await getDoc(userRef);
  
      if (userSnap.exists()) {
        const userEmail = userSnap.data().email;
  
        // Actualizar documento en preUsers
        const preUsersRef = collection(db, "preUsers");
        const qPreUsers = query(preUsersRef, where("email", "==", userEmail));
        const querySnapshotPreUsers = await getDocs(qPreUsers);
  
        if (!querySnapshotPreUsers.empty) {
          const preUserDoc = querySnapshotPreUsers.docs[0];
          const preUserRef = doc(db, "preUsers", preUserDoc.id);

          const fieldsToDelete = Object.keys(preUserDoc.data()).reduce((acc, field) => {
            if (field !== "email" && field !== "category") {
              acc[field] = deleteField(); // Aquí está la corrección
            }
            return acc;
          }, {});

          await updateDoc(preUserRef, fieldsToDelete);
        } else {
          console.log("No matching preUser document found.");
        }
      } else {
        console.log("User document does not exist.");
      }
    setTimeout(() => {
      setDataFunc({
        ...globalData,
        formData: {
          ...globalData.formData,
          support: {
            ...globalData.formData.support,
            support,
            expertise,
            contacts,
            other,
            different,
            remind,
            completed: true,
          },
          matching: {
            ...globalData.formData.matching,
            completed: true,
          },
        },
      });
      updateLatestActions(userRef, "Saved Support (final) form", isMobile)
      navigate("/investorregistration/matching");
    }, 2000);
  } catch (error) {
    console.log("Error processing submission:", error);
  }
  };
  const expertiseSelectOptions = [
    {
      name: "I/we offer expertise in the fields selected in the 'Industries' section",
      value:
        "I/we offer expertise in the fields selected in the 'Industries' section",
    },
    {
      name: "I offer contacts in the fields selected in the 'Industries' section",
      value:
        "I offer contacts in the fields selected in the 'Industries' section",
    },
    { name: "Other", value: "Other" },
  ];
  const toggleTerms = () => {
    setData({ ...data, showTerms: !showTerms });
  };
  const {
    support,
    expertise,
    contacts,
    other,
    different,
    remind,
    showTerms,
    error,
    message,
    loading,
  } = data;
  return (
    <div className="shadow-grey w-[90%]  rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className="flex items-end pl-3 md:pl-0">
        <div
          className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
        >
          <img className=" h-full  " src={SupportLogo} alt="Company" />
        </div>
        <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
          Support
        </h1>
      </div>
      <form className=" " onSubmit={handleSubmit}>
        <div className="mt-5 px-3 md:pl-24">
          <div className="mb-2">
            <label htmlFor="support">
              Prefer Startup participation in a generator, incubator or
              accelerator program [optional]
            </label>
          </div>
          <InvestorButton
            supportProps={{ dataProps: data, setDataFunc: setData }}
          />
        </div>
        <div className="flex items-end pl-3 md:pl-0">
          <div
            className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
          >
            <img className=" h-full  " src={ExpertiseLogo} alt="icon" />
          </div>
          <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
            Expertise
          </h1>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <h1 htmlFor="expertise">
            Additional value/expertise you contribute to the startups (How
            'smart' is your money?)
          </h1>
          {expertiseSelectOptions.map((selection) => {
            return (
              <div key={selection.value} className="mt-3  flex items-center ">
                <Switch
                  onChange={() =>
                    setData({
                      ...data,
                      expertise: {
                        ...data.expertise,
                        [selection.value]: !data.expertise[selection.value],
                      },
                    })
                  }
                  checked={data.expertise[selection.value]}
                  id={selection.value}
                />
                <label htmlFor={selection.value} className="ml-5">
                  {selection.name}
                </label>
              </div>
            );
          })}
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="different">If other, what expertise?</label>
          <input
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            type="text"
            name="different"
            id="different"
            placeholder="Other expertise"
            value={different}
            onChange={handleChange}
          ></input>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="remind">
            Remind me to review / update my match criteria in:{" "}
          </label>
          <select
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            name="remind"
            id="remind"
            value={remind}
            onChange={handleChange}
          >
            <option value="6 Months">6 Months</option>
            <option value="1 Year">1 Year </option>
            <option value="2 Years">2 Years </option>
            <option value="3 Years">3 Years</option>
          </select>
        </div>
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}
        <div className=" my-4 px-3 md:pl-24 flex flex-col  justify-between items-center text-sm ">
          <h1 className="my-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={agreeToTerms}
                required
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              <span className="ml-2">
                I agree to the{" "}
                <button
                  type="button"
                  required
                  onClick={() => {
                    toggleTerms();
                  }}
                  className="text-blue-500 underline underline-offset-4 cursor-pointer"
                >
                  Terms of Use
                </button>
              </span>
            </label>
          </h1>

          <input
            type="submit"
            disabled={loading}
            className=" bg-green-500 my-4 cursor-pointer rounded border border-gray-500 p-2.5  text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
            value="Submit"
          ></input>
        </div>
      </form>
      {showTerms && <Terms toggleTerms={toggleTerms} />}
    </div>
  );
};

export default InvestorSupport;
