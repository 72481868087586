import React, { useContext } from "react";
import { navBarContext } from "./context/context";

const Otp = () => {
    const { navBarHeight } = useContext(navBarContext);

  return (
    <div
    id="forgotPw__"
    style={{
      marginTop: navBarHeight + 70,
      marginBottom: navBarHeight + 10,
      width: "100%",
      minWidth: "366px",
      padding: "2%",
    }}
  >
    <section
      className={`
        bg-gradient-to-b from-gray-800 to-gray-900
        shadow-black mx-auto
        w-11/12 md:max-w-lg
        rounded-md
        py-2.5 px-5
        shadow-md
      `}
    >
      <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
        Enter OTP
      </h1>
      <div className="mt-5" style={{ textAlign: "center", marginBottom: "20px" }}>
        <p className="mb-4">Please enter the OTP we sent to your phone number:</p>
        <form  >
          <input
            type="text"
            className={`
              w-full
              bg-gray-700
              rounded-md
              py-2 px-3
              text-gray-200
              focus:outline-none
              focus:ring-2
              focus:ring-blue-600
            `}
          />
           <div className="my-2.5 mx-0 text-center">
    <input
      type="submit"
      className="
        uppercase bg-[#16a34a24] w-full 
        my-2 cursor-pointer rounded 
        border border-green-500 p-2.5 
        text-base text-white outline-none 
        transition-all duration-300 ease-in-out 
        hover:scale-105 hover:bg-green-600 hover:border-white"
      value="Reset Password"
    />
  </div>
        </form>
      </div>
    </section>
  </div>
);
}

export default Otp;
