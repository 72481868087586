import React, {useContext, useRef} from "react";
import { NotFound } from "@peterfosso/prairi-components";
import { useNavigate } from "react-router-dom";
import { navBarContext, authContext } from "./context/context";


const NotFound404 = () => {
    const { navBarHeight } = useContext(navBarContext);
    const { currentUser } = useContext(authContext);
    let navigate = useNavigate();
    const containerRef = useRef(null);

    return (
        <div
        className="md:max-w-lg p-1 from-navStart to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 4 }}
      >
        <NotFound click={()=>navigate("/")}/>
        </div>
    )
}

export default NotFound404;