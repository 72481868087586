import { navBarContext } from "./context/context";
import Logo from "../assets/prairiwhite.png";
import { Link } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NewInvestor, PrairiButton } from "@peterfosso/prairi-components";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "../firebase/firebaseApp";

const InvestorInterest = (props) => {
  const { navBarHeight } = useContext(navBarContext);

  const [knowThem, setKnowThem] = useState(null);
  const [pitched, setPitched] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  let { docId, invId } = useParams();

  const [dataFetched, setDataFetched] = useState(false);

  const [investorData, setInvestorData] = useState({});


  const getDataFromDb = async (invId) => {
    try {
      const invRef = doc(db, "users", invId)
      const invDoc = await getDoc(invRef)
      //console.log(invId)
      //console.log('investor data', JSON.stringify(invDoc.data()))
      setInvestorData(invDoc.data())
    }
    catch (error) {
      //console.log(error);
    }
  }

  const fetchData = async (invId) => {
    try {
  await getDataFromDb(invId);
  setDataFetched(true)
    } catch (error) {
      // Handle errors if any of the functions throw an error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(invId);
  }, [])




  const handleKnowThem = (event) => {
    setKnowThem(event.target.value);
  };

  const handlePitched = (event) => {
    setPitched(event.target.value);
  };

  const handleButtonClick = (event) => {
    if (!knowThem || !pitched) {
      event.preventDefault();
      setErrorMessage("Please answer both questions");
    }
    else {
      setErrorMessage(null)
    }
  }


  return (
    <div
      id="newMatch__"
      style={{
        marginTop: navBarHeight + 70,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
      {dataFetched && (<div>
      <section
        id="newMatch__container"
        className={`
        w-9/12 max-w-[560px] 
        bg-body
        shadow-black m-auto  rounded-md
        py-2.5 px-5 shadow-md
        min-w-[380px]
       `}
      >
        <div className="py-6">
          <div
            className=" 
        align-center
        justify-center
        content-center
        items-center"
          >
            <NewInvestor investor={investorData} />
            <div className="px-6">
              {/* 
              <div className="flex justify-around mb-5">
                <form>
                  <input type="radio" name="radio-group" value="yes" className="mr-2" onChange={handleKnowThem} required />
                  <label for="yes" className="mr-6">YES</label>
                  <input type="radio" name="radio-group" value="no" className="mr-2" onChange={handleKnowThem} required />
                  <label for="no" className="mr-6">NO</label>
                  <input type="radio" name="radio-group" value="not-sure" className="mr-2" onChange={handleKnowThem} required />
                  <label for="not-sure" className="mr-6">NOT SURE</label>
                </form>
              </div>
              <p className="mb-2"><strong>Have you pitched to them in the last 6 months?</strong></p>
              <div className="flex justify-around col">
                <form>
                  <input type="radio" name="radio-group" value="yes" className="mr-2" onChange={handlePitched} required />
                  <label for="yes" className="mr-6">YES</label>
                  <input type="radio" name="radio-group" value="no" className="mr-2" onChange={handlePitched} required />
                  <label for="no" className="mr-6">NO</label>
                  <input type="radio" name="radio-group" value="not-sure" className="mr-2" onChange={handlePitched} required />
                  <label for="not-sure" className="mr-6">NOT SURE</label>
                </form>

              </div> */}
              <p className="text-s italic mt-3 text-redPrairi">{errorMessage}</p>

            </div>
          </div>
        </div>
      </section>
      </div>)}
    </div>
  );
};

export default InvestorInterest;//