import React, { useContext, useEffect, useState } from "react";
import ProfileComplete from "../../assets/profile_complete.png";
import Logo from "../../assets/prairiwhite.png";
import Phone from "../../assets/phone.png";
import { investorContext, authContext } from "../context/context";
import { useMediaQuery } from "react-responsive";
import { getDataFromUser } from "../../utils";

import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../../firebase/firebaseApp";

const InvestorMatching = () => {

  const [dataFetched, setDataFetched] = useState(false)
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { setDataFunc, globalData } = useContext(investorContext);
  const data = globalData.formData;
  const { currentUser } = useContext(authContext);
  const allowed =
    data.company.completed &&
    data.industry.completed &&
    data.ownership.completed &&
    data.support.completed;
  

    useEffect(() => {
      window.scrollTo(0, 0);
      const fetchData = async () => {
        const { userData, userId } = await getDataFromUser(currentUser.uid);
        setUserData(userData);
        setUserId(userId);
        setDataFetched(true);
        if (!isMobile && userData && !userData.loginSMS) {
          sendLoginSMS(userData, userId);
        }
      };
      fetchData();
    }, [currentUser.uid]);
    
    const sendLoginSMS = async (userData, userId) => { //Send ONLY ONCE. So we'll store that.
      try {
        const docRef = doc(db, "users", userId);
        await updateDoc(docRef, { loginSMS: true });
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${userData.phone}`,
          body: `Hey, ${userData.contactName.split(' ')[0]}! Congratulations on completing your profile. Don't forget to log in on your phone :) http://prairi.com`,
        };
        await addDoc(messagesCollection, dataToStore);
      } catch (error) {
        console.log(error);
      }
    };
    

  if (allowed) {
    return (
      <div className="shadow-grey w-full rounded-md mt-2 h-full md:max-w-xl mx-auto py-2.5 pb-[300px]">
        <div className="text-center mt-5">
          <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
          <h1 className="font-bold text-center mb-8 text-base">
            Matching Starts 
          </h1>
        </div>

        <div className="flex">
          <img src={ProfileComplete} className="w-8 h-8 ml-2" alt="tick" />
          <div className="ml-5 mr-3">
            <p className="font-bold mb-3">Your profile is complete!</p>
            <p className="mb-3 ">
              PRAIRI will now find you startups you’ll love, based on your
              criteria.
            </p>
            <p className="mb-3 ">
              You’ll be notified via email and SMS (but not more than once per
              day) whenever there’s a match.
            </p>
          </div>
        </div>

        {/*Here goes the phone stuff*/}

        {!isMobile && (
          <div className="flex mt-8">
            <img src={Phone} className="w-12 h-12 ml-2" alt="tick" />
            <div className="ml-5 mr-3">
              <p className="font-bold mb-3">One final step <span className=" text-lg">...</span></p>
              <p className="mb-3 ">
                We just sent you an SMS (or may arrive soon)! Click the link to
                open PRAIRI on your mobile phone so you'll be logged in and
                ready to be notified whenever we have matches for you.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="shadow-grey w-full rounded-md mt-2 h-full md:max-w-xl mx-auto py-2.5 pb-[300px]">
        <div className="text-center mt-5">
          <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
          <h1 className="font-bold text-center mb-8 text-base">
            Matching Pending
          </h1>
        </div>

        <div className="flex">
          <div className="ml-5 mr-3">
            <p className="font-bold mb-3">Oops! Your profile is incomplete.</p>
            <p className="mb-3 ">
              Please complete it so we can help you find your next unicorn.
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default InvestorMatching;
