import React from "react";
import styled from "styled-components";

const InvestorButton = (props) => {
  const { dataProps, setDataFunc } = props.supportProps;
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setDataFunc({ ...dataProps, support: value });
  };
  return (
    <Wrapper>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="yes"
          checked={dataProps.support === "yes"}
          onChange={(event) => handleSelectChange(event)}
        />
        <RadioButtonLabel />
        <div>Yes</div>
      </Item>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="no"
          checked={dataProps.support === "no"}
          onChange={(event) => handleSelectChange(event)}
        />
        <RadioButtonLabel />
        <div>No</div>
      </Item>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="nomatter"
          checked={dataProps.support === "nomatter"}
          onChange={(event) => handleSelectChange(event)}
        />
        <RadioButtonLabel />
        <div>Doesn't matter</div>
      </Item>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #8db600;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 100%;
      }
    }
  `}
`;

export default InvestorButton;
