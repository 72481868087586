import { navBarContext } from "./context/context";
import React, { useContext, useState } from "react";
import { storage } from '../firebase/firebaseApp';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const MatchExample = (props) => {

  const { navBarHeight } = useContext(navBarContext);

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `pdfs/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
        });
      }
    );
  };


  return (
    <div
      id="newMatch__main"
      style={{
        marginTop: navBarHeight + 70,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
<div>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload PDF</button>
      <div>Progress: {progress}%</div>
      {url && <a href={url} target="_blank" rel="noopener noreferrer">View PDF</a>}
    </div>
    </div>
  );
};

export default MatchExample;
