import React, { useState, useContext } from "react";
import { navBarContext } from "./context/context";
import ForgotPasswordSent from "./ForgotPasswordSent";
import { auth, sendPasswordResetEmail } from "../firebase/firebaseApp";
const ForgotPassword = () => {
  const { navBarHeight } = useContext(navBarContext);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForgotPasswordSent, setShowForgotPasswordSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setShowForgotPasswordSent(true);
    } catch (error) {
      setErrorMessage("The email address is not registered");
    }
  };

  return (
    <div
      id="forgotPw__"
      style={{
        marginTop: navBarHeight + 70,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        padding: "2%",
      }}
    >
      {showForgotPasswordSent ? (
        <ForgotPasswordSent email={email} />
      ) : (
        <section
          className={`
            bg-#1e1d
            shadow-black mx-auto
            w-11/12 md:max-w-lg
            rounded-md
            py-2.5 px-5
            shadow-md
          `}
        >
          <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
            Reset Password
          </h1>
          <form
            className="py-0 px-5"
            onSubmit={handleSubmit}
            data-testid="login-"
          >
            <div className="mt-5">
              <label htmlFor="email">Email</label>
              <input
                className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                data-testid="login-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div className="my-2.5 mx-0 text-center">
              <input
                type="submit"
                className="
              uppercase bg-[#16a34a24] w-full 
              my-2 cursor-pointer rounded 
              border border-green-500 p-2.5 
              text-base text-white outline-none 
              transition-all duration-300 ease-in-out 
              hover:scale-105 hover:bg-green-600 hover:border-white"
                value="Reset Password"
              />
            </div>
          </form>
        </section>
      )}
    </div>
  );
};

export default ForgotPassword;
