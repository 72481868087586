import React, { useState, useContext, useEffect } from "react";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import SupportLogo from "../../assets/support.png";
import HearLogo from "../../assets/hear.png";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  deleteField,
  updateDoc,
  addDoc,
  deleteDoc
} from "../../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, getDataFromUser } from "../../utils";
import { dataContext, authContext } from "../context/context";
import ExpertiseLogo from "../../assets/expertise.png";
import Terms from "./../common/Terms";

const Support = () => {
  const { setDataFunc, globalData } = useContext(dataContext);
  const supportData = globalData.formData.support;
  const { currentUser } = useContext(authContext);
  const [userRef, setUserRef] = useState([])
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState({
    support: supportData.support || "",
    pgname: supportData.pgname || "",
    hear: supportData.hear || "",
    expertise: supportData.expertise || "",
    other: supportData.other || "",
    remind: supportData.remind || "",
    error: "",
    loading: false,
    message: "",
    showTerms: false,
  });

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])

  const [agreeToTerms, setAgreeToTerms] = useState(false)

  let navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date());
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  
    setData({
      ...data,
      error: "",
      loading: true,
      message: "Saving... (click progress bar if you wish to edit)",
    });
  
    try {
      const userSnap = await getDoc(userRef);
  
      if (userSnap.exists()) {
        const userEmail = userSnap.data().email;
  
        // Actualizar documento en preUsers
        const preUsersRef = collection(db, "preUsers");
        const qPreUsers = query(preUsersRef, where("email", "==", userEmail));
        const querySnapshotPreUsers = await getDocs(qPreUsers);
  
        if (!querySnapshotPreUsers.empty) {
          const preUserDoc = querySnapshotPreUsers.docs[0];
          const preUserRef = doc(db, "preUsers", preUserDoc.id);

          const fieldsToDelete = Object.keys(preUserDoc.data()).reduce((acc, field) => {
            if (field !== "email" && field !== "category") {
              acc[field] = deleteField(); // Aquí está la corrección
            }
            return acc;
          }, {});

          await updateDoc(preUserRef, fieldsToDelete);
        } else {
          console.log("No matching preUser document found.");
        }
      } else {
        console.log("User document does not exist.");
      }
  
      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            support: {
              ...globalData.formData.support,
              support,
              pgname,
              hear,
              expertise,
              other,
              remind,
              completed: true,
            },
            matching: {
              ...globalData.formData.matching,
              completed: true,
            },
          },
        });
        updateLatestActions(userRef, "Saved Support (final) form", isMobile);
        navigate("/startupregistration/matching");
      }, 2000);
    } catch (error) {
      console.log("Error processing submission:", error);
    }
  };

  const toggleTerms = () => {
    setData({ ...data, showTerms: !showTerms });
  };
  const {
    support,
    pgname,
    hear,
    expertise,
    other,
    remind,
    showTerms,
    error,
    message,
    loading,
  } = data;
  return (
    <div className="shadow-grey w-[90%]   rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className="flex items-end pl-3 md:pl-0">
        <div
          className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
        >
          <img className=" h-full  " src={SupportLogo} alt="Company" />
        </div>
        <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
          Support
        </h1>
      </div>
      <form className=" " onSubmit={handleSubmit}>
        <div className="mt-5 px-3 md:pl-24">
          <div className="mt-5  flex items-center ">
            <label htmlFor="support" className="mr-5">
              The company has participated in a generator, incubator or
              accelerator program
            </label>
            <Switch
              onChange={() =>
                setData({
                  ...data,
                  support: !support,
                })
              }
              checked={support}
              id="support"
            />
          </div>
        </div>
        {support &&
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="pgname">Which one(s)?</label>
          <input
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            type="text"
            name="pgname"
            id="pgname"
            placeholder="Program names separated with comma ','"
            value={pgname}
            onChange={handleChange}
          ></input>
        </div>
        }
        <div className=" mt-5 flex items-end pl-3 md:pl-0">
          <div
            className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
          >
            <img className=" h-full  " src={HearLogo} alt="Company" />
          </div>
          <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
            Contacts
          </h1>
        </div>
        <div className="mt-5 px-3 md:pl-24 flex items-center ">
          <div className="  flex items-center  ">
            <label htmlFor="hear" className="  mr-2  md:mr-5">
              Would you be open to hearing from people who help startups grow?
              (Accelerators, consultants, etc.)
            </label>
            <Switch
              onChange={() =>
                setData({
                  ...data,
                  hear: !hear,
                })
              }
              checked={hear}
              id="hear"
            />
          </div>
        </div>
        <div className=" mt-5 flex items-end pl-3 md:pl-0">
          <div
            className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
          >
            <img className=" h-full  " src={ExpertiseLogo} alt="icon" />
          </div>
          <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
            Expertise
          </h1>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="expertise">
            Most important expertise you look for from an Investor (how 'smart' is their
            money?)
          </label>
          <select
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            name="expertise"
            id="expertise"
            value={expertise}
            onChange={handleChange}
          >
            {" "}
            <option disabled value="">
              Please select
            </option>
            <option value="Expertise in the industry">Expertise in the industry</option>
            <option value="Contacts in the industry">Contacts in the industry</option>
            <option value="Looking for other type of support">Other (please specify)</option>
            <option value="Not looking for specific support">Doesn't matter</option>
          </select>
        </div>
        {expertise==="Looking for other type of support" && 
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="other">What expertise?</label>
          <input
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            type="text"
            name="other"
            id="other"
            placeholder="Other expertise"
            value={other}
            onChange={handleChange}
          ></input>
        </div>
}
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="remind">
            Remind me to review/update my data profile:
          </label>
          <select
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            name="remind"
            id="remind"
            value={remind}
            onChange={handleChange}
          >
            <option disabled value="">
              Please select{" "}
            </option>
            <option value="Monthly (For fast-growing startups)">
              Monthly (For fast-growing startups){" "}
            </option>
            <option value="Every 3 months">Every 3 months </option>
            <option value="Every 6 month">Every 6 months </option>
            <option value="Annually">
              Annually (on the date you registered)
            </option>
          </select>
        </div>
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">Saving... <span className='text-silverPrairi'>(click progress bar if you wish to edit)</span></p>
        )}
        <div className=" my-4 px-3 md:pl-24 flex flex-col  justify-between items-center text-sm ">
        <span className="ml-2">
        <label className="inline-flex items-center">    
        <input
      type="checkbox"
      className="form-checkbox h-5 w-5 text-blue-600 mr-2"
      checked={agreeToTerms}
      required
      onChange={() => setAgreeToTerms(!agreeToTerms)}
    />
      I agree to the {" "}
      <button
        type="button"
        onClick={() => {
          toggleTerms()  
        }}
        className="text-blue-500 underline underline-offset-4 cursor-pointer ml-2"
      >
         Terms of Use
      </button>
      </label>
    </span>
    
          <input
            type="submit"
            disabled={loading}
            className=" bg-green-500 my-4 cursor-pointer rounded border border-gray-500 p-2.5  text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
            value="Submit"
          ></input>
        </div>
      </form>
      {showTerms && <Terms toggleTerms={toggleTerms} />}
    </div>
  );
};

export default Support;
//