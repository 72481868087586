import React, { useContext,useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../assets/settings.png";
import { navBarContext, authContext } from "./context/context";
import { query, collection, db, where, getDocs } from "../firebase/firebaseApp";
import DataProfile from "./DataProfileStartup";
import ForgotPassword from "./ForgotPassword";
import Terms from "./common/Terms";
import Privacy from "./Privacy"




const Settings = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let navigate = useNavigate();

  
  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      if (userData) {
        setDataFetched(true);
        setUserData(userData);

      }
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  
  const [showDataProfile, setShowDataProfile] = useState(false);
  const [userData, setUserData] = useState({})
  const [dataFetched, setDataFetched] = useState(false);
  const handleDataProfile = async () => {
    const { userData } = await getDataFromDb();
    setShowDataProfile(true);
    if (userData.category==="investor")
    navigate("/dataprofile/investor");
    else{
      navigate("/dataprofile/startup");
    }

  };
  const [showTerms, setShowTerms] = useState(false);
    const toggleTerms = () => {
    setShowTerms(!showTerms);
  };
  const [showPrivacy, setShowPrivacy] = useState(false);
    const togglePrivacy = () => {
      setShowPrivacy(!showPrivacy);
    }
  
    useEffect(() => {
      getDataFromDb();
      // console.log("data fetched?")
    }, [currentUser]);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  useEffect(() => {
    if (dataFetched && !currentUser) {
      redirectToLogin();
    }
  }, [currentUser]);

  const [showPassword, SetShowPassword]= useState(false);
  const handlePassword = () =>{
    SetShowPassword(true);
    navigate("/forgotpassword")
  }
  const handleEditDataProfile = async () => {
    const { userData } = await getDataFromDb();
    if (userData.category === "startup") {
      navigate("/startupregistration");
    } else {
      navigate("/investorregistration");
    }
  };

  const handlePauseDataProfile = async () => {
    const { userData } = await getDataFromDb();
    if (userData.paused) {
      navigate("/reactivate");
    } else {
      navigate("/matchpause");
    }
  };

  const handleNotifications = () => {
    navigate("/notificationsettings")
  }
  if (dataFetched && userData && userData.seeking==="NOT SEEKING") {

    return(
      <div
      className={` w-full  md:max-w-2xl md:mx-auto border-customWhite  from-navStart to-navEnd  py-8 px-4`}
      style={{ marginTop: navBarHeight + 4 ,  backgroundColor: "#262626 ",
      }}
    >
      <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 className="font-bold text-center w-full text-customWhite md:text-xl my-4">
        Account Settings{" "}
      </h1>

          <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
            <button onClick={handleNotifications} className="underline my-2">
              Notification settings
            </button>
            <button onClick={handlePassword} className="underline my-2">
              Change your password
            </button>
            <button onClick={toggleTerms} className="underline my-2">
              Read our Terms & Conditions
            </button>
            <button onClick={togglePrivacy} className="underline my-2">
              Privacy Policy
            </button>
          </div>
        
    </div>
    )
  }
  else{

    return (
    
      <div
        className={` w-full  md:max-w-2xl md:mx-auto border-customWhite  from-navStart to-navEnd  py-8 px-4`}
        style={{ marginTop: navBarHeight + 4 ,  backgroundColor: "#262626 ",
        }}
      >
        <div className="w-3/12 mx-auto mt-8 mb-4">
          <img className="w-full" src={SettingsIcon} alt="Settings icon" />
        </div>
        <h1 className="font-bold text-center w-full text-customWhite md:text-xl my-4">
          Account Settings{" "}
        </h1>

            {dataFetched && <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
              <button onClick={handleNotifications} className="underline my-2">
                Notification settings
              </button>
              <button onClick={handlePassword} className="underline my-2">
                Change your password
              </button>
              <button onClick={handleDataProfile} className="underline my-2">
                Data Profile
              </button>
              <button onClick={handleEditDataProfile} className="underline my-2">
                Edit your data profile
              </button>
              <button onClick={handlePauseDataProfile} className="underline my-2">
                Pause/Reactivate your match profile
              </button>
              <button onClick={toggleTerms} className="underline my-2">
                Read our Terms & Conditions
              </button>
              <button onClick={togglePrivacy} className="underline my-2">
                Privacy Policy
              </button>
            </div>}
          
      </div>
    );
  }
  
};

export default Settings;