/* eslint-disable no-unused-vars */
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import React, { useState, useContext, useEffect } from "react";
import { updateLatestActions } from "../utils";
import { useParams, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { TextButton, Modal, PrairiButton, FounderNote } from "@peterfosso/prairi-components";
import Logo from "../assets/prairiwhite.png";
import {
  collection,
  db,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
  getDoc,
  setDoc,
} from "../firebase/firebaseApp";

const InvitedInvitation = () => {
  let { docId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const [data, setData] = useState({
    username: "",
    email: "",
    url: "",
    previous: "",
    category: "",
    error: "",
    loading: false,
    message: "",
    showCaptcha: false,
    verified: false,
    usernameError: false,
    emailError: false,
    countryError: false,
    urlError: false,
    linkedinError: false,
    companyError: false,
    pitchdeckError: false,
    seekingInvestmentError: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "potential", docId);
        const docData = await getDoc(docRef);
        console.log(`data: ${JSON.stringify(docData.data())}`);
        if (docData.exists()) {
          setData(docData.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocumentData();
  }, [docId]);

  const showCaptchaHandler = (e) => {
    e.preventDefault();
    if (!username || !email || !url || !companyName || !previous) {
      console.log("error, data empty")
      setData({
        ...data,
        usernameError: !username,
        emailError: !email,
        urlError: !url,
        companyError: !companyName,
      });
      return; // Exit the function early if any field is empty
    }
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (!urlRegex.test(url)) {
      setData({
        ...data,
        urlError: true,
      });
      return; // Exit the function early if URL is invalid
    }
  
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setData({
        ...data,
        emailError: true,
      });
      return; // Exit the function early if email is invalid
    }
      setData({ ...data, showCaptcha: true });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const usersRef = collection(db, "users");
      const potentialDocRef = doc(db, "potential", docId);
      const potentialDoc = await getDoc(potentialDocRef);
      //console.log("handlesubmit before potentialDoc.exists");
      if (potentialDoc.exists()) {
        const potentialData = potentialDoc.data();
        const newData = {
          ...potentialData, // Copy data from potential document
          ...data, // Include new attributes from the form
        };

        const newDocRef = await addDoc(usersRef, newData);

        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
            {
              name: "Peter",
              email: "pfosso@gmail.com",
            },
          ],
          subject: "You have a new REFERRED investor invitation request",
          textContent: "Please approve the following request",
          templateId: 18,
          params: {
            NAME: username,
            CATEGORY: "REFERRED Investor",
            EMAIL: email,
            COMPANYURL: url,
            COMPANYNAME: companyName,
            PREVIOUS: previous,
            DOCID: newDocRef.id,
          },
        };
        const { data: responseData } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
        setConfirmationPopup(true);
        updateLatestActions(newDocRef, "Requested invitation. Referred", isMobile)
      } else {
        console.log("Potential document does not exist");
      }
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };


  const {
    username,
    email,
    showCaptcha,
    previous,
    companyName,
    url,
    usernameError,
    emailError,
    urlError,

  } = data;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "2%",
      }}
      className="flex flex-col justify-center items-center bg-invitation  w-full h-full  "
    >
      <div
        style={{
          backgroundColor: "#1e1e1d ",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
          borderRadius: "30px",
          width: isMobile ? "90%" : "40%",
        }}
        className=""
      >
        <div className="flex justify-end  text-sm pr-5 pt-2 ">
          <TextButton
            color="yellow"
            click={() => navigate("/")}
            label="Close"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
        <h3 className=" pt-5 text-center text-base md:text-lg text-white">
        Welcome {username.split(" ")[0]}! Join PRAIRI as an investor here
        </h3>
        </div>

        <form className="justify-center flex py-1" onSubmit={showCaptchaHandler}>
          <div>
            <div className="flex items-center justify-between mb-7 px-9">
              <div className="">
                <div className="mt-5">
                  <label htmlFor="username">Your Name</label>
                  <input
                    className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Name"
                    value={username}
                    onChange={handleChange}
                  ></input>
                  {usernameError && (
                    <div className="w-full text-sm text-red-500 mt-2">
                      Name is required
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  <label htmlFor="username">Company Name</label>
                  <input
                    className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                    type="text"
                    name="companyName"
                    id="companeName"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={handleChange}
                  ></input>
                  {data.companyError && <p className="w-full text-sm text-red-500 mt-2">Company name is required</p>}
                </div>

                <div className="mt-5">
                  <label htmlFor="url">Company URL</label>
                  <input
                    className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                    type="url"
                    name="url"
                    id="url"
                    placeholder="https://"
                    value={url}
                    onChange={handleChange}
                  ></input>
                  {urlError && (
                    <div className="w-full text-sm text-red-500 mt-2">
                      Invalid URL
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <label htmlFor="email">
                    Company Email 
                  </label>
                  <input
                    className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={handleChange}
                  ></input>
                  {emailError && (
                    <div className="w-full text-sm text-red-500 mt-2">
                      Invalid Email
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  <label htmlFor="url">
                    Examples of your startup investments
                  </label>
                  <input
                    className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                    type="text"
                    name="previous"
                    id="previous"
                    placeholder="Names of various startups separated by commas"
                    value={previous}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
            <div className=" text-center ">
              {showCaptcha && (
                <ReCAPTCHA
                  className="flex justify-center pt-4 mb-5"
                  sitekey="6LfiNNgjAAAAAC_oJEe3mXyKzDky3ncopW49_R3p"
                  onChange={handleSubmit}
                />
              )}
              <input
                type="submit"
                className=" 
                uppercase bg-green-600 
                cursor-pointer w-40 h-8 
                border-gray-500 border           
                text-sm ml-4  mb-4 outline-none  "
                value="Join now"
                style={{
                  backgroundColor: "#92d050",
                  borderRadius: "5px",
                  color: "black",
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <Modal open={confirmationPopup} border="green" >
      <div className="text-center">
        <FounderNote click={()=>navigate("/")}/>
        </div>
      </Modal>
    </div>
  );
};
export default InvitedInvitation;
