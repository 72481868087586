import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  Timestamp,
  updateDoc,
} from "../firebase/firebaseApp";
import { updateLatestActions, getDataFromUser } from "../utils";
import { navBarContext } from "./context/context";

const Seeker = () => {
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false)
  const [name, setName] = useState("")


  useEffect(() => {
    const getDataFromDb = async () => {
      try {
        // Get user data from email
        const q = query(collection(db, "users"), where("id", "==", docId));
        const querySnapshot = await getDocs(q);
        let userData;
        let userId;
        querySnapshot.forEach((doc) => {
          userData = doc.data();
          userId = doc.id;
        });
        setUserData(userData);
        setDataFetched(true)
        return { userData, userId };
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    const handleApprove = async () => {
      try {
        const userRef = doc(db, "users", docId);
        const time = Timestamp.fromDate(new Date())
        const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
        await updateDoc(userRef, { pendingApproval: false,
                                    sentForReview: false,
                                    "formData.company.deck": userData.pendingDeck
                                  }); 
        updateLatestActions(userRef, "Pitch deck approved", false);
        const docRef = doc(db, "users", docId);
        const docSnap = await getDoc(docRef);
        const { username, email } = docSnap.data();
        setName(username)
        const payload = {
            sender: {
              name: "PRAIRI",
              email: "invite@prairi.com",
            },
            to: [
              {
                username,
                email,
              },
            ],
            templateId: 53,
            params: {
              DOCID: docId,
            },
          };
          await axios({
            method: "POST",
            url: "https://api.sendinblue.com/v3/smtp/email",
            headers: {
              Accept: "application/json",
              "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
              "Content-Type": "application/json",
            },
            data: JSON.stringify(payload),
          });
        // Document successfully updated
        console.log("Document updated successfully.");
      } catch (error) {
        // Handle the error
        console.log("Error updating document:", error);
      }
    };



    getDataFromDb();

    handleApprove();
  }, [docId]);

  return (
    <div className={` w-full `} style={{ marginTop: navBarHeight + 4 }}>
      {dataFetched && <h1 className="my-4 text-center text-lg md:text-xl  ">
        Confirmation sent to {name}!!
      </h1>}
    </div>
  );
};

export default Seeker;
