import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SettingsIcon from "../assets/settings.png";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions } from "../utils";
import { navBarContext, authContext } from "./context/context";
import { PrairiButton, Loading } from "@peterfosso/prairi-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Timestamp } from "../firebase/firebaseApp";
import { query, collection, db, where, getDocs, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../assets/prairiwhite.png";



const MatchPause = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmUndo, setConfirmUndo] = useState(false);
  const [docId, setDocId] = useState(null);
  const [buttonText, setButtonText] = useState("Pause data profile");
  const [reason, setReason] = useState(null)
  const [otherReason, setOtherReason] = useState(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({})
  const [dataFetched, setDataFetched] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const time = Timestamp.fromDate(new Date())
  const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  // console.log("npm start");
  //console.log(docId);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    if (!currentUser && dataFetched) {
      redirectToLogin();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setDocId(currentUser.uid);
    }
  }, [currentUser]);

  const getDataFromUser = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setCurrentUserData(userData)
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        setDataFetched(true)

      }
    } catch (error) {
      //console.log('error fetching data');
    }
  }

  useEffect(() => {
    fetchData();
    //console.log("data fetched?" + JSON.stringify(dataFetched))
    //console.log(currentUserData)

  }, [currentUser]);

  const closePopupsPause = () => {
    setConfirmUndo(false);
    setConfirmed(false);
    navigate("/reactivate")
  }

  const closePopupsUndo = () => {
    setConfirmUndo(false);
    setConfirmed(false);
  }

  const handlePause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused
      });
      //console.log("paused? " + userPaused)

      const userRef = doc(db, "users", userId);
      if (reason === null) {
        setShowErrorMessage(true);
        return;
      }
      else {
        if (reason !== "other") {
          await updateDoc(userRef, { paused: true, "paused.reason": reason, "paused.time": time }); // update the value of the `category` field
        }
        else {
          await updateDoc(userRef, { paused: true, "paused.reason": otherReason, "paused.time": time});
        }
      }
      const updatedDoc = await getDoc(userRef);
      updateLatestActions(userRef, "Paused profile", isMobile)
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      //console.log("logging handlePause")
      // console.log("userId: " + userId)
      //console.log("paused? " + userPaused)

      setConfirmed(true)

      return;

    } catch (error) {
      // console.log(error);
    }
  };
  const handleRemoveProfile = () => {
    navigate('/remove');
  };
  const handleUnpause = async (e) => {
    try {
      // Get user data from email
      const { uid } = currentUser;
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      let userPaused;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
        userPaused = doc.data().paused
      });
      //console.log("paused? " + userPaused)
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { paused: false }); // update the value of the `category` field
      updateLatestActions(userRef, "Reactivated profile", isMobile)
      const updatedDoc = await getDoc(userRef);
      userPaused = updatedDoc.data().paused; // update the value of `userPaused`
      //console.log("logging handleUnpause")
      //console.log("userId: " + userId)
      //console.log("paused? " + userPaused)
      setConfirmUndo(true)
      setConfirmed(false)
      setButtonText("Pause data profile")
      return;

    } catch (error) {
      // console.log(error);
    }
  }

  const WhiteRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);


if (!dataFetched){
  return (<div className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
  style={{
    marginTop: navBarHeight + 30,

  }}><Loading/></div>)
}


  return (


    <div
      className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
      style={{
        marginTop: navBarHeight + 4, backgroundColor: "#1e1e1e",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "60px"
      }}
    >
      

      {dataFetched && currentUserData.category === "startup" &&
        <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
                <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black md:text-xl my-4" >
        Pause your match profile{" "}
      </h1>
      <h2 className="font-bold text-center w-full text-customWhite md:text-xl my-4" >
        Reason for pausing my Data Profile:
      </h2>
      
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              name="radio-buttons-group"
              className={`pt-3`}
              required
            >
              <FormControlLabel
                value="Found funding on Prairi"
                control={<WhiteRadio />}
                label={
                  <span>
                    We just received our funding on PRAIRI
                  </span>
                }
              />
              <FormControlLabel
                value="Found funding NOT on Prairi"
                control={<WhiteRadio />}
                label={
                  <span>
                    We received our funding (but not on PRAIRI)
                  </span>
                }
              />
              <FormControlLabel
                value="Not pursuing investment"
                control={<WhiteRadio />}
                label={
                  <span>
                    We've decided not to pursue investment at this time
                  </span>
                }
              />
              <FormControlLabel
                value="other"
                control={<WhiteRadio />}
                label={
                  <span>
                    Other reason:
                    <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3 text-black mx-0.5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" />
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
          {showErrorMessage && <p className="text-redPrairi">Please select a reason for pausing.</p>}

          <div className="my-2 mt-8 flex-1/3 gap-1 justify-between">
            {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}

            <button className="flex-1/3 bg-yellowPrairi text-black p-2 rounded-md  outline outline-2     outline-offset-2 "
              onClick={handlePause}
            >
              Pause data profile
            </button>
            <button
              onClick={() => navigate("/settings")} className=" mx-7 flex-auto justify-items-end outline outline-2  p-2 rounded-md outline-offset-2">Back</button>
          </div>
          <button className="flex md:jusify-items-end lg:justify-evenly p-3 underline " onClick={handleRemoveProfile} >Remove Profile</button>
        </div>
      }

      {dataFetched && currentUserData.category === "investor" &&
        <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
                <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black md:text-xl my-4" >
        Pause your match profile{" "}
      </h1>
      <h2 className="font-bold text-center w-full text-customWhite md:text-xl my-4" >
        Reason for pausing my Data Profile:
      </h2>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              name="radio-buttons-group"
              className={`pt-3`}
              required
            >
              <FormControlLabel
                value="Getting too many notifications"
                control={<WhiteRadio />}
                label={
                  <span>
                    Getting too many notifications
                  </span>
                }
              />
              <FormControlLabel
                value="Not enough quality matches"
                control={<WhiteRadio />}
                label={
                  <span>
                    Not enough quality matches
                  </span>
                }
              />
              <FormControlLabel
                value="Too many matches"
                control={<WhiteRadio />}
                label={
                  <span>
                    Too many matches
                  </span>
                }
              />
              <FormControlLabel
                value="other"
                control={<WhiteRadio />}
                label={
                  <span>
                    Other reason:
                    <input onChange={(event) => setOtherReason(event.target.value)} className="rounded-md border-blackish flex-3 text-black mx-0.5 border-solid  border-2  border-color: yellowPrairi w-64" type="textarea" />
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
          {showErrorMessage && <p className="text-redPrairi">Please select a reason for pausing.</p>}

          <div className="my-2 mt-8 flex-1/3 gap-1 justify-between">
            {/* <div className="my-2  grid grid-cols-2 gap-3 justify-between"></div> */}

            <button className="flex-1/3 bg-yellowPrairi text-black p-2 rounded-md  outline outline-2     outline-offset-2 "
              onClick={handlePause}
            >
              Pause data profile
            </button>
            <button
              onClick={() => navigate("/settings")} className=" mx-7 flex-auto justify-items-end outline outline-2  p-2 rounded-md outline-offset-2">Back</button>
          </div>
          <button className="flex md:jusify-items-end lg:justify-evenly p-3 underline " onClick={handleRemoveProfile} >Remove Profile</button>
        </div>
      }



      <Modal
        open={confirmed}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[250px] min-h-[270px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxWidth: "450px",
            maxHeight: "300px",
            height: "50%",
            borderRadius: "50px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px"
          }}

        ><img src={Logo} className="w-1/6 h-auto mx-auto mb-5" alt=""></img>

          <p className="mb-2 text-center">
            Your profile has been paused successfully.
          </p>
          <p className="text-center">
            We’ll check back with you from time to time to see when you’re ready to come back!
          </p>
          <div>
      <div className="flex justify-center mt-5 items-between">
        <PrairiButton bgColor="lighter" label="OK" click={closePopupsPause} size="md" rounded="soft" />
        <button
          className="font-[sans-serif] text-[#f2f2f2] underline rounded-full w-[4rem]"
          onClick={handleUnpause}
        >
          <p>undo</p>
        </button>
      </div>
      <div className="flex justify-center"></div>
    </div>

          <div
            className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
          >
          </div>
        </Box>
      </Modal>

      <Modal
        open={confirmUndo}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[150px] min-h-[100px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25%",
            maxWidth: "450px",
            maxHeight: "300px",
            height: "40%",
            borderRadius: "50px",
            padding: "10px 17px 2px 15px",
            opacity: "1",
            fontSize: "16px"
          }}

        ><img src={Logo} className="w-1/6 h-auto mx-auto mb-2" alt=""></img>

          <p className="mb-2 text-center">
            Ok!
          </p>
          <p className="text-center">
            Your profile is active again.
          </p>

          <div className="flex justify-center ">
            <button className="py-1.5 px-3 text-lg mt-2 mb-2 text-center rounded-lg bg-customWhite text-backgroundPrairi border-2 border-lighterGray justify-end w-1/4"
              onClick={() => closePopupsUndo()}>
              OK
            </button>
          </div>

          <div
            className="
                  flex 
                  justify-end 
                  items-center 
                  align-center
                  mt-4
                  w-1/2
                  mx-auto"
          >
          </div>
        </Box>
      </Modal>
      {/*Add pause reason */}

    </div>
  );
};


export default MatchPause;

