import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/prairiwhite.png";
import { FounderNote, Modal } from "@peterfosso/prairi-components"

function SeekingStartup() {
  const [openModal, setOpenModal] = useState(true);
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleOKButtonClick = () => {
    setOpenModal(false);
    navigate("/");
  };


  return (
    <div className="flex p-8 items-center justify-between text-base md:text-lg">
        <Modal
        open={openModal}
        transparent
        size="xl"
      >

        <div className="text-center">
        <FounderNote click={()=>navigate("/")}/>
        </div>


      </Modal>
      </div>
  );
}

export default SeekingStartup;
