import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/newprairilogo.png"
import { navBarContext, authContext } from "./context/context";
import { updateLatestActions } from "../utils";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { PrairiButton } from "@peterfosso/prairi-components";
import { Timestamp, doc, updateDoc, db, query, collection, where, getDocs } from "../firebase/firebaseApp";

const ThatsAll = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [userId, setUserId] = useState("")
  const time = Timestamp.fromDate(new Date())
  const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const getDataFromUser = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setUserId(userId)
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  }

  useEffect(() => {
    fetchData();
    //console.log("data fetched?" + JSON.stringify(dataFetched))
    //console.log(currentUserData)

  }, [currentUser]);

  const storeView = async (userId) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, "Went through all matches")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    storeView(userId)
  }, []);

  return (
    <div style={{ marginTop: navBarHeight + 4 }} className="flex-col justify-center" >

      <img src={Logo} className="mx-auto w-14 h-auto my-4"></img>
      <h4 className='text-center'>That's all for now</h4>
      <div className="text-center my-4">
        <h4><strong>Stay tuned for more matches</strong></h4>

      </div>
      <div className="text-start ml-5 my-4 md:max-w-md mx-auto my-6">
        <h4 className='mb-5'>Clicked on <span className="text-greenPrairi font-bold">'Interested'</span>? </h4>
        <h4 className='mb-5'>You can now find them on your Dashboard.</h4>
        <p>Be sure to  <strong>contact them right away,</strong> since there likely are other investors who've also matched with them</p>
      </div>
      <div className="  flex justify-center " >
        <Link to="/Dashboard">
          <PrairiButton 
          bgColor='regular'
            size='md'
            click={() => {/*console.log('Button clicked!')*/}}
            label="OK"
            border='no-border'
            rounded='soft'
          />
        </Link>
      </div>



    </div>
  );
};

export default ThatsAll;
