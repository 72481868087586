/* eslint-disable no-unused-vars */
import axios from "axios";
import { storage } from "../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { navBarContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import React, { useState, useContext, useEffect } from "react";
import { updateLatestActions } from "../utils";
import { useParams, useNavigate } from "react-router-dom";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { TextButton, Modal, PrairiButton } from "@peterfosso/prairi-components";
import Logo from "../assets/prairiwhite.png";
import {
  collection,
  db,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
  getDoc,
} from "../firebase/firebaseApp";

const SecondInvitation = () => {
  let { docId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { navBarHeight } = useContext(navBarContext);
  const [confirmationPopup, setConfirmationPopup] = useState(false)
  const [field, setField] = useState({
    companyField: false,
    linkedinField: false,
    pitchField: false,
  });
  const [data, setData] = useState({
    username: "",
    email: "",
    url: "",
    linkedin: "",
    pitchdeck: "",
    companyName: "",
    previous: "",
    seeking: "",
    category: "",
    error: "",
    loading: false,
    message: "",
    showCaptcha: false,
    verified: false,
    usernameError: false,
    emailError: false,
    countryError: false,
    urlError: false,
    linkedinError: false,
    pitchdeckError: false,
    seekingInvestmentError: false,
  });
  const [pitchFile, setPitchFile] = useState(null);
  const [pitchProgress, setPitchProgress] = useState(0);
  const [fileName, setFileName] = useState("")
  const [pdfUrl, setPdfUrl] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const isFinallyValid = () => {
    let dataCopy = { ...data };
    if (!validator.isURL(url)) {
      dataCopy = {
        ...dataCopy,
        urlError: true,
        error: "Fill all the required fields",
      };
    }
    if (!validator.isURL(linkedin)) {
      dataCopy = {
        ...dataCopy,
        linkedinError: true,
        error: "Fill all the required fields",
      };
    }
    if (!validator.isURL(pitchdeck)) {
      dataCopy = {
        ...dataCopy,
        pitchdeckError: true,
        error: "Fill all the required fields",
      };
    }
    setData({ ...dataCopy });
    // override global variables
    const { urlError, linkedinError, pitchError } = dataCopy;
    return urlError || linkedinError || pitchError
      ? false
      : true;
  };

  const handleField = () => {

    if (data.rejected === "we can't access your website.") {
        setField( { url: true , ...field});
    } else if (data.rejected === "we need the LinkedIn URL for you or your company.") {
        setField( { linkedin: true , ...field});
    } else if (data.rejected === "we need your pitch deck." || data.rejected === "your pitch deck is password protected or requires an email address to be viewed." || data.rejected === "your pitch deck does not meet investor standards." || data.rejected === "your pitch deck is not in English."){
        setField( { pitchdeck: true , ...field});
    }
};


const handleFileChange = (e) => {
  setPitchFile(e.target.files[0]);
  setFileName(e.target.files[0] ? e.target.files[0].name : '');
};

const handleUpload = () => {
  return new Promise((resolve, reject) => {
    if (!pitchFile) {
      reject("No file selected");
      return;
    }

    const storageRef = ref(storage, `pdfs/${pitchFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, pitchFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPitchProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setPdfUrl(downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "preUsers", docId);
        const docData = await getDoc(docRef);
        //console.log(`data: ${JSON.stringify(docData.data())}`)
        if (docData.exists()) {
          setData(docData.data());

        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocumentData();
    handleField();
  }, [docId, data.rejected]);

  const showCaptchaHandler = (e) => {
    e.preventDefault();
    if (isFinallyValid()) {
      setData({ ...data, showCaptcha: true });
    }
  };



  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const updateFieldValidity = (bool, fieldName) => {
    if (bool) {
      setData({ ...data, [`${fieldName}Error`]: true });
    } else {
      setData({ ...data, [`${fieldName}Error`]: false });
    }
  };

  const isValid = (fieldName) => {
    switch (fieldName) {
      case "url":
        updateFieldValidity(!validator.isURL(url), fieldName);
        break;
      case "linkedin":
        updateFieldValidity(!validator.isURL(linkedin), fieldName);
        break;
      // case "pitchdeck":
      //   updateFieldValidity(!validator.isURL(pitchdeck), fieldName);
      //   break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    console.log(`isValid ${JSON.stringify(isValid)}`)
    console.log(`isFinallyValid ${JSON.stringify(isFinallyValid)}`)
    setData({
      ...data,
      error: "",
      showCaptcha: false,
      verified: true,
      loading: true,
      message: "Sending request...",
    });
    if (isValid){
        try {
            const pdfUrl = await handleUpload();
            let userRef = doc(db, "preUsers", docId);
            await updateDoc(userRef, {
              url: url,
              linkedin: linkedin,
              pitchdeck: pdfUrl,
              rejected: false
            });
      
            updateLatestActions(
              userRef,
              "Requested invitation after rejection",
              isMobile
            );
      
            //Send an email to admin about a new invitation request
            const payload = {
              sender: {
                name: "PRAIRI",
                email: "invite@prairi.com",
              },
              to: [
                {
                  name: "Carolina",
                  email: "kro.ruz@gmail.com",
                },
                  {
                    name: "Peter",
                    email: "peter@prairi.com",
                  },
                  {
                    name: "Peter",
                    email: "pfosso@gmail.com",
                  },
              ],
              subject: `${category} requested to join after being rejected`,
              textContent: "Please approve the following request",
              templateId: 47,
              params: {
                NAME: username,
                EMAIL: email,
                COMPANYURL: url,
                LINKEDINURL: linkedin,
                PITCHDECK: pdfUrl,
                DOCID: docId,
                CATEGORY: category
                // COUNTRY: country,
              },
            };
            const { data } = await axios({
              method: "POST",
              url: "https://api.sendinblue.com/v3/smtp/email",
              headers: {
                Accept: "application/json",
                "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
                "Content-Type": "application/json",
              },
              data: JSON.stringify(payload),
            });
            // console.log(data);
            setConfirmationPopup(true)
          } catch (err) {
            console.log(err);
            setData({
              ...data,
              error: err.message,
              loading: false,
              message: "",
            });
          }
    }

  };

  const {
    username,
    email,
    showCaptcha,
    verified,
    category,
    error,
    loading,
    message,
    url,
    linkedin,
    latest,
    createdAt,
    pitchdeck,
    usernameError,
    emailError,
    urlError,
    linkedinError,
    pitchdeckError,
  } = data;

  return (
    <div
      style={{
        marginTop: navBarHeight + 30,
        marginBottom: navBarHeight + 10,
        width: "100%",
        minWidth: "366px",
        maxWidth: "700px",
        padding: "2%",
      }}
      className="flex flex-col justify-center items-center"
    >
      <div
        style={{
          backgroundColor: "#1e1e1d ",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
          borderRadius: "30px",
          width: "100%",
        }}
      >
        <div className="flex justify-end  text-sm pr-5 pt-2">
          <TextButton
            color="yellow"
            click={() => navigate("/")}
            label="Close"
          />
        </div>
        <h1 className="text-center text-xl text-silverPrairi uppercase ">
          Welcome back, {username.split(" ")[0]}!
        </h1>
        <p className=" text-center text-sm text-gray-500">{email}</p>
        <div className="px-5 pt-5">
          <p>
            Last time you were here, we couldn't accept your request because{" "}
            {data.rejected}
          </p>
          <p className="mt-2">
            If you have corrected this, please complete the form below, and
            we'll review your request again.
          </p>
        </div>

        <div></div>
        <form className=" p-2 px-4" onSubmit={showCaptchaHandler}>
          {field.url && <div className="mt-5">
            <label htmlFor="url">Company URL</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="url"
              id="url"
              placeholder="https://"
              value={url}
              onChange={handleChange}
              onBlur={() => isValid("url")}
            ></input>
            {urlError && (
              <div className="w-full text-sm text-red-500 mt-2">
                Invalid URL
              </div>
            )}
          </div>}

          { field.linkedin && <div className="mt-5">
            <label htmlFor="linkedin">LinkedIn URL</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="linkedin"
              id="linkedin"
              placeholder="Or verify yourself with a link to your Twitter, Facebook, or Wellfound"
              value={linkedin}
              onChange={handleChange}
              onBlur={() => isValid("linkedin")}
            ></input>
            {linkedinError && (
              <div className="w-full text-sm text-red-500 mt-2">
                Invalid URL
              </div>
            )}
          </div>}

          {field.pitchdeck && 
          <div className="flex items-center justify-start">
          <input
            type="file"
            name="pitchdeck"
            id="pitchdeck"
            accept="application/pdf"
            onChange={handleFileChange}
            className="hidden"
            required
          />
          <label htmlFor="pitchdeck" className="px-4 py-2 my-2 bg-[#3A3A3A] text-white rounded cursor-pointer hover:bg-greenPrairi">
            Choose file
          </label>
          <p className="ml-4 italic text-silverPrairi truncate w-[70%]">{fileName}</p>
        </div>}

          <div className=" text-center mt-5">
            <button
              onClick={handleSubmit}
              className=" 
                uppercase bg-green-600 mb-4
                cursor-pointer w-40 h-8 
                border-gray-500 rounded border 10%      
                text-sm ml-4 outline-none text-black "
              value="Send request"
              style={{ backgroundColor: "#92d050", borderRadius: "5px" }}
            >
              SEND REQUEST
            </button>
          </div>
        </form>
      </div>
      <Modal open={confirmationPopup} border="green" >
                <div className="">
                <img src={Logo} className="w-1/6 h-auto mx-auto mb-4" alt=""></img>

<p className="mb-2 text-center">
  Thank you!
</p>
<p className=" mb-2 text-center">

You will receive an email as soon as you're approved.</p>
<div className="text-xs italic">
<p className=" mb-2">If you don't see one soon, please check your junk folder.</p>

</div>
<div className="flex justify-center ">
  <PrairiButton
    click={()=>navigate("/")}
    label="OK"
    border="green"
    size="sm"
    rounded="soft"
/>
</div>
                </div>
      </Modal>
    </div>
  );
};
export default SecondInvitation;
