import React, { useEffect,useState } from "react";
import LandingPage from "./LandingPage/LandingPage";
//import DashboardNavbar from "./DashboardNavbar";


const StartupNomatchDashboard = () => {
    const [isInterestedClicked, setIsInterestedClicked] = useState(false);
    const [showDashboard, setShowDashboard] = useState(true); // new state variable



  const [showStartups, setShowStartups] = useState(true);
  const [investorFeedback, setinvestorFeedback] = useState(false);
  const [interestedButtonStyle, setInterestedButtonStyle] = useState({
    borderColor: "#a5a5a5"
  });
  useEffect(() => {
    setInterestedButtonStyle({
      borderColor: showStartups ? "#92d050" : "#a5a5a5"
    });
  }, []);
  const [investorButtonStyle, setinvestorButtonStyle] = useState({
    borderColor: "#00b0f0" 
  });
  const [notification, setNotification] = useState("");

 

  const setInterestedClick = () => {
   // console.log("Interested Matches button clicked");
    setShowStartups(true);
    setinvestorFeedback(false);
    setInterestedButtonStyle({ borderColor: "#92d050" });
    setinvestorButtonStyle({ borderColor: "#a5a5a5" });
    setNotification("");
    setIsInterestedClicked(true);
  };
  const [showFeedback, setShowFeedback] = useState(false);


  const handleinvestorMatchesClick = () => {
    setShowStartups(false);
    setinvestorFeedback(true);
    setInterestedButtonStyle({ borderColor: "#a5a5a5" });
    setinvestorButtonStyle({ borderColor: "#00b0f0" });
    setShowFeedback(true);
    setIsInterestedClicked(false);
  };
  const handleClose = () => {
    setShowDashboard(false); // set the state variable to false
  };

  if (!showDashboard) {
    return < LandingPage />;
  }

  
  return (
    <div>
      <div className="p-4 max-w-5xl grid gap-4 xs:grid-cols-1 bg-[#262626] xs:grid-cols-1 ">

      <section>
      {notification && <p>{notification}</p>}
          <div>
              <div className="p-4 max-w-5xl grid gap-1 xs:grid-cols-1"> 
            <p style={{ marginBottom: "10px" }}></p>
            <p style={{ marginBottom: "10px" }}></p>
            <p style={{ marginBottom: "10px" }}></p>
          </div>
        </div>
        <div>
          <div className="p-4 max-w-5xl grid gap-4 xs:grid-cols-1">
          <div
            id="interested__investor__buttons"
            className="
              flex p-2
              text-[2.2vw]
              max-h-[70px]
              sm:text-[1em]
              flex-row 
              
              justify-center space-x-5 items-center
            "
            >
             

              <button
              onClick={setInterestedClick}
              className="
            
              font-[sans-serif] text-[#a5a5a5] tracking-wide font bg-#00b0f0 hover:bg-gradient-to-t border-[2px] border-[#a5a5a5]  rounded-md py-1 
                  
              w-[25vw] max-w-[150px] min-w-[94px]
              sm:w-[150px] 
              
            "
            style={interestedButtonStyle}
            >
              Interested
            </button>
            <button
              onClick={handleinvestorMatchesClick}
              className="
              
                font-[sans-serif] text-[#a5a5a5] tracking-wide font bg-#2E2E2E hover:bg-gradient-to-t border-[2px] border-[#a5a5a5]  rounded-md py-1 
                    
                w-[22vw] max-w-[150px] min-w-[94px]
                sm:w-[150px] 
              "
              style={investorButtonStyle}
            >
              Investor Feedback
            </button>
            </div>
            {isInterestedClicked && (
              <div className="grid grid-col  items-center mt-2 text-center">
  
              <p className="p-4 max-w-5xl grid gap-4 xs:grid-cols-1 text-[#f2f2f2] font-[sans-serif] ">
              <strong>Your matches</strong></p>
              <br></br>
             <span>No matches yet</span> 
             <br></br>
              But there are sure to be very soon!<br /><br />
              we'll definitely notify you (email and SMS) <br></br>
              when we find great matches for you based<br></br> on your criteria.<br /><br />
              Of course, you can always widen the <br></br>
              criteria a bit in you Data Profile for more<br></br> matches at any time , but we recommend<br></br> only doing so after maybe a few weeks<br></br> of not receiving  any matches.
          
            <div style={{ display: 'flex', justifyContent: 'center' }}>
             <button 
             onClick={() => {}}
             className="
             font-[sans-serif] text-[#a5a5a5] tracking-wide font bg-#2E2E2E hover:bg-gradient-to-t border-[2px] border-[#a5a5a5]  rounded-md py-1 
             w-[36vw] max-w-[150px] min-w-[94px]
             sm:w-[150px] mt-10
             sm:text-[1em]
             max-h-[70px]
             sm:text-[1em]
             flex-row 
             tracking-wide
             text-[3.2vw]
             mr-9
              "
             >
              
            Update Profile
            </button>
         
            <button
                        className=" font-[sans-serif] text-[#a5a5a5] tracking-wide font bg-#2E2E2E   rounded-md py-1 
                        w-[22vw] max-w-[150px] min-w-[94px]
                        sm:w-[150px] mt-10
                         text-greenPrairi s  mt-10 text-sm pt-1 pr-1 pb-1 pl-25 underline "
                        onClick={handleClose}
                      >
                         
                        <p>Close</p>
                    </button>
        
            </div>
        
             </div>

        
             )}
            
            </div>
            
            <div>
            {investorFeedback && (
            <div className="grid grid-col items-center  mt-2 text-center">
            <div> 
             <p>
               <em>
                 <strong>No investor feedback yet</strong>
               </em>
               <br /><br />
   
               But hang in there and keep moving forward!<br /><br />
   
               We’ll definitely notify you when an investor<br></br> clicks ‘Interested’ after they’ve matched with you. <br /><br />
   
               And if any have instead provided some anonymous <br></br>feedback for you, you can see it under the ‘<span style={{ color: '#00b0f0' }}>Investor Feedback</span>’  tab. <br /><br />
   
               <span class="text-gray-500">Have there been any changes or areas of growth in your company?</span>

             </p>
             </div>

             <div>
             <button 
             onClick={() => {}}
             className="
             font-[sans-serif] text-[#a5a5a5] tracking-wide font bg-#2E2E2E hover:bg-gradient-to-t border-[2px] border-[#a5a5a5]  rounded-md py-1 
             flex p-2
             text-[2.2vw]
             max-h-[70px]
             sm:text-[1em]
             mx-auto
             
             justify-center space-x-5 
           
             w-[22vw] max-w-[150px] min-w-[94px]
             sm:w-[150px] mt-10
              "
             >
              
            Update Profile
            </button>
            </div>
             </div> 
             
          
        
        
     )}
     </div>
     </div>
    </section>
    
  </div>
  </div>
);
};

export default StartupNomatchDashboard;
