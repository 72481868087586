import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import InvestorBreadcrumbStart from "../investor-breadcrumb/InvestorBreadcrumbStart";
import InvestorBreadcrumbMiddle from "../investor-breadcrumb/InvestorBreadcrumbMiddle";
import InvestorBreadcrumbLast from "../investor-breadcrumb/InvestorBreadcrumbLast";
import { countryRegions, usStates } from "../../countries.js";
import axios from "axios";
import CryingIcon from "../../assets/unicorn-crying.png";
import { Modal, PrairiButton, SaveReminder } from "@peterfosso/prairi-components";
import { investorContext, navBarContext, authContext } from "../context/context";
import {
  query,
  collection,
  db,
  where,
  updateDoc,
  doc,
  getDocs,
} from "../../firebase/firebaseApp";
const InvestorRegistration = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const didMount = useRef(false);
  let navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false)
  const [userFormData, setUserFormData] = useState({});
  const [savePopup, setSavePopup] = useState(false)
  // //Determine current URL, store in state and update current breadcrumb color
  let location = useLocation();
  // // This component will act as central place for all nested routes to update state
  const [data, setData] = useState({
    currentLocation: "",
    formData: {
      company: {
        company: "",
        country: "",
        //state: "",
        startupcountries: [],
        startupstates : usStates,
        investments: "",
        investmentGroups: "",
        majority: {
          "Equity investments": false,
          "Revenue-based financing": false,
          "Services in exchange for equity": false
        },
        completed: false,
        contactName: "",
        startupAllocates: false,
        photo: ""
      },
      industry: {
        industry: {
          "AdTech/Media": false,
          "Big Data": false,
          "Consumer Goods": false,
          "Cybersecurity": false,
          "Deep Tech (AI, Web3, Blockchain, etc.)": false,
          "eCommerce": false,
          "Emerging Tech (NFTs, VR, Robotics)": false,
          "EdTech": false,
          "Entertainment/Music/Gaming": false,
          "Fashion": false,
          "FoodTech": false,
          "Fintech/Finance": false,
          "Greentech/Cleantech/Energy": false,
          "HealthTech/MedTech": false,
          "IT/Software/SaaS": false,
          "PropTech": false,
          "Retail": false,
          "Service Industry Solutions": false,
          "Space Technology": false,
          "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)": false         
        },
        model: {
          "B2C": false,
          "B2B": false,
        },
        stage: {
          early: false,
          growth: false,
          series: false,
          higher: false,
        },
        currency: "EUR",
        amount: [3000000, 6000000],
        completed: false,
      },
      
      founders: {
        founders: "",
        nonfounder: "",
        diversity: {
          "woman": false,
          "POC": false,
          "LGBTQ+": false,
          "immigrant": false,
          "person with disabilities": false,
          "senior/65+": false,
          "teen/19-": false
        }
      },
      ownership: {
        capitalization: {
          single: false,
          double: false,
          triple: false,
          team: false,
          mixed: false,
        },
        traction: {
          users: false,
          norevenue: false,
          revenue: 0,
          
        },
        expectation: {
          three: false,
          six: false,
          twelve: false,
          year: false,
          long: false,
        },
        minimum: null,
        completed: false,
      },
      support: {
        support: false,
        expertise: false,
        contacts: false,
        other: false,
        different: "",
        remind: "six",
        completed: false,
      },
      matching: {
        completed: false,
      },
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setUserFormData(userData.formData)
      return { userData, userId };
    } catch (error) {
     // console.log(error);
    }
  };

  const updateLocalData = async () => {
    const { userData } = await getDataFromDb();
    if (userData.category === "investor") {
      if (!userData.agreed && userData.country!=="United States"){
        //console.log(JSON.stringify(userData))
        navigate("/investorwelcome")
      }
      setData({ ...data, ...userData, currentLocation: "company" });
    } else {
      navigate("/startupregistration");
    }
  };

  const sendErrorEmail = async () => {
    try {
//console.log('send email' + JSON.stringify(currentUser))
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            name: "Caro",
            email: "caro@prairi.com"
          },
        ],
        templateId: 45,
        params: {
          DOCUID: currentUser.uid,
          FILE: "InvestorRegistration.js"
        },
      };
      await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const fireNotification = async () =>{
    sendErrorEmail()
    navigate("/")
  }
  const sendDataToDb = async () => {
    const { userId } = await getDataFromDb();
    try {
      await updateDoc(doc(db, "users", userId), { ...data });
    } catch (error) {
      console.log(error);
      console.log(data)
      sendErrorEmail()
      setErrorPopup(true)
    }
  };
  useEffect(() => {
    let currentLocation = location.pathname.split("/").pop();
    setData({ ...data, currentLocation });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    updateLocalData();
  }, []);
  useEffect(() => {
    if (didMount.current) {
      sendDataToDb();
    } else {
      didMount.current = true;
    }
  }, [data.formData]);

  return (
    <div
      className="w-full px-0.5 bg-rainbowBg "
      style={{ paddingTop: navBarHeight }}
    >
      <div className={` w-full bg-body pt-2`}>
        <h1 className="my-2 md:my-6 text-center text-base md:text-xl text-gray-500 uppercase">
          Investor Data Profile
        </h1>
        <investorContext.Provider
          value={{
            setDataFunc: setData,
            globalData: data,
          }}
        >
          <nav className=" flex justify-around items-center md:justify-center w-full px-2 ">
            <Link to="company">
              <InvestorBreadcrumbStart title="Company" 
              displayTitle="Company" 
              />
            </Link>
            <Link to="industry">
              <InvestorBreadcrumbMiddle
                title="Industry"
                displayTitle="Industry, Stage & Investment"
              />
            </Link>
            <Link to="founders">
              <InvestorBreadcrumbMiddle
                title="Founders"
                displayTitle="Founders & Team"
              />
            </Link>
            <Link to="ownership">
              <InvestorBreadcrumbMiddle
                title="Ownership"
                displayTitle="Ownership & Traction"
              />
            </Link>{" "}
            <Link to="support">
              <InvestorBreadcrumbMiddle
                title="Support"
                displayTitle="External Support"
              />
            </Link>
            <Link to="matching" style={{ marginLeft: 10 }}>
              <InvestorBreadcrumbLast
                title="matching"
                displayTitle="Matching Starts"
              />
            </Link>
          </nav>
          <div>
            <Outlet />
          </div>
        </investorContext.Provider>
      </div>


      <Modal open={errorPopup} border="silver">
        <div className="flex justify-center flex-col">
          <div className="flex justify-center mb-2"><img className="flex justify-center w-[15%]" src={CryingIcon}></img></div>
          <div className="flex justify-center font-bold my-2">
            We’re sorry, but this isn’t working.{" "}
          </div>
          <div className="flex  mb-2">
            It’s not you it’s us… it’s our fast growth and frequent site
            updates.{" "}
          </div>
          <div className="flex  mb-2">
            Please try again later. Thank you for your patience!{" "}
          </div>
          <div className="flex justify-center"><PrairiButton border="green" label="OK" size="sm" rounded="soft" click={()=>navigate("/")}/></div>
        </div>
      </Modal>


    </div>
  );
};

export default InvestorRegistration;