import React from "react";

// startup context
const dataContext = React.createContext({});

// investor context
const investorContext = React.createContext({});

const navBarContext = React.createContext({});

// Auth context for current signed in user
const authContext = React.createContext({});
export { dataContext, investorContext, navBarContext, authContext };
