import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../assets/settings.png";
import { navBarContext, authContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions } from "../utils";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  Timestamp
} from "../firebase/firebaseApp";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PrairiButton, TextButton } from "@peterfosso/prairi-components";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";

const Notifications = () => {
  const time = Timestamp.fromDate(new Date())
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const useStyles = makeStyles((theme) => ({
    label: {
      fontSize: "1px",
    },
  }));
  let navigate = useNavigate();
  const classes = useStyles();
  const WhiteRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "#92d050",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

    const [dataFetched, setDataFetched] = useState(false);
  const [settings, setSettings] = useState("");
  const [saved, setSaved] = useState(false);

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });

      setSettings((prevState) => {
        if (userData.smsConsent && userData.emailConsent) {
          return "SMS and email (default)";
        } else if (userData.smsConsent) {
          return "SMS only";
        } else if (userData.emailConsent) {
          return "Email only";
        } else {
          return "Turn off all notifications";
        }
      });
      setDataFetched(true);
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataFromDb();
    };
    fetchData();
  }, []); // Empty dependency array to run only once on mount




  const handleChange = (event) => {
    setSettings(event.target.value);
    setSaved(false)
  };

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  const handleSettings = async () => {
    try {
      const { userData, userId } = await getDataFromDb();
      const userRef = doc(db, "users", userId);
      let updatedSettings = ""; // Create a variable to hold the updated settings
  
      if (settings === "SMS and email (default)") {
        await updateDoc(userRef, { smsConsent: true, emailConsent: true});
        updateLatestActions(userRef, "Changed notification settings", isMobile)
        updatedSettings = "SMS and email (default)";
      }
      if (settings === "SMS only") {
        await updateDoc(userRef, { smsConsent: true, emailConsent: false});
        updateLatestActions(userRef, "Changed notification settings", isMobile)
        updatedSettings = "SMS only";
      }
      if (settings === "Email only") {
        await updateDoc(userRef, { smsConsent: false, emailConsent: true });
        updateLatestActions(userRef, "Changed notification settings", isMobile)
        updatedSettings = "Email only";
      }
      if (settings === "Turn off all notifications") {
        await updateDoc(userRef, { smsConsent: false, emailConsent: false});
        updateLatestActions(userRef, "Changed notification settings", isMobile)
        updatedSettings = "Turn off all notifications";
      }
  
      // Use the updatedSettings value
      setSettings(updatedSettings);
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (dataFetched && !currentUser) {
      redirectToLogin();
    }
  }, [currentUser]);

  return (
    <div
      className={` w-full m-4 md:max-w-2xl md:mx-auto border-customWhite  from-navStart to-navEnd  py-8 px-4`}
      style={{
        marginTop: navBarHeight + 4,
        backgroundColor: "#262626 ",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "30px",
        width: "100%",
      }}
    >
      <div className="w-3/12 mx-auto mt-8 mb-4">
        <img className="w-full" src={SettingsIcon} alt="Settings icon" />
      </div>
      <h1 className="font-bold text-center w-full  md:text-xl my-4">
        Notification Settings{" "}
      </h1>
      <div className=" text-md text-textPrairi">
        For users who don't wish to immediately know whether they have a match,
        it's possible to alter your notification settings (but it's{" "}
        <span className="underline">not</span> recommended).
      </div>
      <div className="flex justify-start flex-col items-center mt-5">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="settings"
            name="settings"
            value={settings}
            onChange={handleChange}
          >
            <FormControlLabel
              value="SMS and email (default)"
              control={<WhiteRadio />}
              label="SMS and email (default)"
            />
            <FormControlLabel
              value="SMS only"
              control={<WhiteRadio />}
              label="SMS only"
            />
            <FormControlLabel
              value="Email only"
              control={<WhiteRadio />}
              label="Email only"
            />
            <FormControlLabel
              value="Turn off all notifications"
              control={<WhiteRadio />}
              label="Turn off all notifications"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="flex justify-between items-center mx-5">
        <div>
          <PrairiButton
            label="Save"
            size="md"
            border="red"
            rounded="soft"
            click={() => handleSettings()}
          />
          {saved && <p>Your changes have been saved!</p>}
        </div>
        <TextButton
          label="back"
          color="silver"
          click={() => navigate("/settings")}
        />
      </div>
    </div>
  );
};

export default Notifications;