import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateLatestActions } from "../utils";
import { FounderNote, Modal } from "@peterfosso/prairi-components";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../firebase/firebaseApp";

function Success() {
  const [openModal, setOpenModal] = useState(true);
  const [currentUserData, setCurrentUserData] = useState({});

  const [userRef, setUserRef] = useState({});
  let { docId } = useParams();
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOKButtonClick = () => {
    setOpenModal(false);
    navigate("/");
  };

  useEffect(() => {
    const getDataFromDb = async () => {
      try {
        const docRef = doc(db, "preUsers", docId);
        const docSnap = await getDoc(docRef);
        let userData;
        let userId;
        if (docSnap.exists()) {
          userData = docSnap.data();
          userId = docSnap.id;
        } else {
          console.log("No such document!");
        }
        setCurrentUserData(userData);
        return { userData, userId };
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };
  
    

    const handleValidate = async () => {
      try {
        // Get the user document by its ID
        const docRef = doc(db, "preUsers", docId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          // Update the document
          await updateDoc(docRef, { paymentPending: false });
          updateLatestActions(docRef, "Requested invitation, PAID", false);
          
          // Document successfully updated
          console.log("Document updated successfully.");
        } else {
          console.log("No document found with the specified ID.");
        }
      } catch (error) {
        // Handle the error
        console.log("Error updating document:", error);
      }
    };
    
    

    const sendRequest = async () => {
      try {
        const docRef = doc(db, "preUsers", docId);
        const docSnap = await getDoc(docRef);
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
          ],
          subject: `You have a new ${
            docSnap.data().category
          } invitation request (PAID)`,
          textContent: "Please approve the following request",
          templateId: 18,
          params: {
            NAME: docSnap.data().username,
            CATEGORY: "Startup",
            EMAIL: docSnap.data().email,
            COMPANYURL: docSnap.data().url,
            LINKEDINURL: docSnap.data().linkedin,
            SEEKING: docSnap.data().seeking,
            PITCHDECK: docSnap.data().pitchdeck,
            
            //CODE: docSnap.data().code,
            DOCID: docId,
          },
        };
        await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    getDataFromDb();
    handleValidate();
    sendRequest();
  }, [docId]);

  return (
    <div className="flex p-8 items-center justify-between text-base md:text-lg">
        <Modal
        open={openModal}
        transparent
        size="xl"
      >

        <div className="text-center">
        <FounderNote click={()=>navigate("/")}/>
        </div>


      </Modal>
      </div>
  );
}

export default Success;
