import FilterStrategy from './FilterStrategy.js';
import {ERR_INVALID_INVESTOR, ERR_INVALID_STARTUP} from './util/error_messages.mjs';

class LocationFilter extends FilterStrategy {
    doFilter(investor, startups) {
        this.guard(investor, startups);
       
        const startupCountries = new Set(investor.formData.company.startupcountries);
        const includesAnywhere = startupCountries.has("ANYWHERE");
        const includesUS = startupCountries.has("United States");
        const investorStates = new Set(investor.formData.company.startupstates);

        const filteredData = startups.filter(startup => {
            const inCountry = includesAnywhere || startupCountries.has(startup.formData.company.country);

            if (!inCountry) return false;

            if (includesUS && startup.formData.company.country === "United States") {
                return investorStates.has(startup.formData.company.state);
            }

            return true;
        });

        return filteredData;
    }
}


export default LocationFilter;
