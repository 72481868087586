import React from "react";
import styles from "./NextButton.module.css";
const NextButton = ({ value }) => {
  return (
    <div className={styles.RainbowButton}>
      <input
        type="submit"
        className={styles.InputButton}
        data-testid="nextButton-1"
        value={value}
      ></input>
    </div>
  );
};

export default NextButton;
