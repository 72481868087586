import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PrairiButton } from "@peterfosso/prairi-components";
import { updateLatestActions } from "../utils";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "../firebase/firebaseApp";
import { navBarContext } from "./context/context";

const SeekerReject = () => {
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState("");
  const [reason, setReason] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [sent, setSent] = useState(false);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };


  const reasonOptions = [
    {
      value: 'we need your pitch deck.',
      label: 'We need your pitch deck',
      explanation: 'To ensure investors can spot the perfect match, we kindly request your pitch deck. It helps us better understand your goals and facilitates more accurate matches within our startup and investor community.',
    },
    {
      value: "your pitch deck is password protected or requires an email address to be viewed.",
      label: "Your pitch deck is password protected or requires an email address to be viewed.",
      explanation: "In order to keep our users anonymous, we require that your pitch deck is accessible without the need for an email address or password. This way an investor who matches with you can view it to decide whether they are interested in pursuing things further - and, if not interested, can provide you with honest, helpful feedback, anonymously.",
    },
    {
      value: "we can't access your website.",
      label: "We can't access your website",
      explanation: "In order to keep our users anonymous, we require that your pitch deck is accessible without the need for an email address or password. This way an investor who matches with you can view it to decide whether they are interested in pursuing things further - and, if not interested, can provide you with honest, helpful feedback, anonymously.",
    },
    {
      value: "we need the LinkedIn URL for you or your company.",
      label: "We need the LinkedIn URL for you or your company",
      explanation: "Please provide your LinkedIn URL. We need this to help verify our users. If you don’t have one, you can provide your profile address from another social media site, such as X/Twitter or Facebook.",
    },
    {
      value: "your pitch deck does not meet investor standards.",
      label: "Your pitch deck does not meet investor standards",
      explanation: "Thanks so much for requesting to join. However, our team has looked at your pitch deck and, unfortunately, it doesn’t quite meet the standards we require for the investors on PRAIRI. Please research what a great pitch deck looks like (e.g. https://pitch.com/blog/15-great-pitch-decks-from-successful-startups) "
    },
    {
      value: "your pitch deck is not in English.",
      label: "Your pitch deck is not in English",
      explanation: "Thank you for your interest. However, your pitch deck needs to be in English to ensure it can be easily understood by our international investor community. Please translate your pitch deck to English before resubmitting it."
    }
  ];

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  const getUserData = async (investorId) => {
    try {
      const userDocRef = doc(collection(db, "users"), docId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUserData(userData);
      } else {
        console.log(`Investor document with ID ${investorId} not found`);
      }
    } catch (error) {
      console.error("Error fetching investor data:", error);
    }
  };

  const fetchData = async (docId) => {
    try {
      await getUserData(docId);
      setDataFetched(true);
      // console.log(`investor data: ${JSON.stringify(investorData)}`)
    } catch (error) {
      // console.log("data NOT fetched")
    }
  };

  useEffect(() => {
    fetchData(docId);
  }, [docId]);

  const storeRejection = async (reason) => {
    try {
      const userRef = doc(db, "users", docId);
      updateLatestActions(userRef, "Rejected", false)
      // Document successfully updated
      console.log("Document updated successfully.");
    } catch (error) {
      // Handle the error
      console.log("Error updating document:", error);
    }
  };

  const sendRejection = async () => {
    try {
      if (reason === "other") {
        setReason(otherReason);
      }
      const selectedOption = reasonOptions.find((option) => option.value === reason);

      const docRef = doc(db, "users", docId);
      const docSnap = await getDoc(docRef);
      const { username, code, email } = docSnap.data();
      setName(username);
      setReason(reason);
      let nameArray = username.split(" ");
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            username,
            email,
          },
        ],
        templateId: 52,
        params: {
          NAME: nameArray[0],
          REASON: reason,
          EXPLANATION: selectedOption ? selectedOption.explanation : '',
          DOCID: docId
        },
      };
      await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const handleRejection = async () => {
    storeRejection(reason);
    sendRejection();
    setSent(true);
  };

  return (
    <div
      className="w-full flex flex-col "
      style={{ marginTop: navBarHeight + 4 }}
    >
      {dataFetched && (
        <h1 className="text-2xl mt-2 justify-center flex">
          Reject {userData.username}
        </h1>
      )}

      <div className="flex justify-start flex-col items-center mt-5">
        <label className="flex justify-start text-xl mt-5">
          Select reason:
        </label>
        <FormControl component="fieldset">
        <RadioGroup
        aria-label="reason"
        name="reason"
        value={reason}
        onChange={handleReasonChange}
      >
        {reasonOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
        </FormControl>
      </div>
      <div className="flex justify-center items-center">
        <PrairiButton
          label="Send rejection"
          size="md"
          border="red"
          rounded="soft"
          click={() => handleRejection()}
        />
        {sent && <p>Sent!</p>}
      </div>
    </div>
  );
};

export default SeekerReject;
