// Strategy interface
import {ERR_INVALID_FILTER, ERR_NO_FILTERS_SELECTED} from './util/error_messages.mjs'
import FilterStrategy from './FilterStrategy.js';
import LocationFilter from './LocationFilter.js';
import StageFilter from './StageFilter.js';
import IndustryFilter from './IndustryFilter.js';
import AmountFilter from './AmountFilter.js';
const filters = ['location', 'stage', 'industry', 'amount'];

class InvestorMatcher {
    constructor() {
        this._filters = [];
    }

    set strategies(filters) {
        this._filters = filters;
    }

    get strategies() {
        return this._filters;
    }

    addFilter(filter) {
        this._filters.push(this.toFilter(filter));
    }

    doFilter(investor, startups) {
        if (!this._filters.length) {
            throw new Error(ERR_NO_FILTERS_SELECTED);
        }
        const filteredData = [];
        for (const startup of startups) {
            let matchesAllFilters = true;
            for (const filter of this._filters) {
                const result = filter.doFilter(investor, [startup]);
                if (result.length === 0) {
                    matchesAllFilters = false;
                    break; // No need to continue checking other filters for this startup
                }
            }
            if (matchesAllFilters) {
                filteredData.push(startup);
            }
        }

        if (filteredData.length === 0) {
            // Handle the case when there are no matches
            // You can throw an error, return a special value, or handle it in any way you prefer
            
            throw new Error("No matches found.");
        }
        return (function*() {
            for (const startup of startups) {
                if (this._filters.every(filter => filter.doFilter(investor, [startup]).length)) {
                    yield startup;
                }
            }
            // No se encontraron coincidencias, finalizar el generador
            return; 
        }.bind(this))(); 
    }
    

    toFilter(filter){
        if (typeof filter === 'string') {
            // Create an instance of the corresponding strategy based on the string value
            if (filter === 'industry') {
                filter = new IndustryFilter();
            }else if (filter === 'stage') {
                filter = new StageFilter();
            }else if (filter === 'amount') {
                filter = new AmountFilter();
            }else if (filter === 'location') {
                filter = new LocationFilter();
            }else {
                throw new Error(ERR_INVALID_FILTER);
            }
        } else if (!(filter instanceof FilterStrategy)) {
            throw new Error(ERR_INVALID_FILTER);
        }
        return filter;
    }
}

export { InvestorMatcher, filters };