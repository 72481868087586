import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CryingIcon from "../assets/unicorn-crying.png";
import { navBarContext, authContext } from "./context/context";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { query, collection, db, where, getDocs, setDoc, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Logo from "../assets/prairiwhite.png";
import { QuerySnapshot } from "firebase/firestore";


const RemoveConfirmation = () => {
    const { navBarHeight } = useContext(navBarContext);
    const { currentUser } = useContext(authContext);
    let navigate = useNavigate();


    useEffect(() => {
        const timer = setTimeout(() => {
          navigate("/"); // Redirect to the home page after 10 seconds
        }, 5000);
    
        return () => clearTimeout(timer); // Clear the timer on component unmount
      }, [navigate]);

    return (
        <div
            className={`md:max-w-2xl md:mx-auto  from-navStart to-navEnd px-4`}
            style={{
                marginTop: navBarHeight + 4, backgroundColor: "#1e1e1e",
                boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
                borderRadius: "60px"
            }}
        >
            <div className="w-3/12 mx-auto mt-8 mb-4">
                <img className="w-3/4" src={CryingIcon} alt="Settings icon" />
            </div>
            <h1 style={{ color: "white" }} className="font-bold text-center w-full text-black md:text-xl my-4" >
                Your profile has been deleted successfully
            </h1>
            <p className="flex justify-center">You'll be redirected now.</p>

            <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">


            </div>

        </div>


    );
};


export default RemoveConfirmation;

