import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { PrairiButton } from "@peterfosso/prairi-components";
import { navBarContext, authContext } from "./context/context";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  getDoc,
  deleteField,
  doc,
  Timestamp,
  updateDoc,
  addDoc,
} from "../firebase/firebaseApp";
import { updateLatestActions } from "../utils";

const DeckApproval = () => {
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  const [height, setHeight] = useState(window.innerHeight);
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [newDeck, setNewDeck] = useState("")
  const [approved, setApproved] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [userRef, setUserRef] = useState({})
  const { currentUser } = useContext(authContext);
  const { uid } = currentUser || {};
  let navigate = useNavigate();
  const isAdminUser = currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  const getDataFromDb = async () => {
    try {
      const userRef = doc(db, "users", docId);
      const userSnap = await getDoc(userRef);
      const newDeck = userSnap.data().pendingPitchDeck;
      setNewDeck(newDeck)
      setUserData(userSnap.data());
      setDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async () => {
    try {
      const userRef = doc(db, "users", docId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        await updateDoc(userRef, {
            "formData.company.deck": newDeck,
            pendingPitchDeck: deleteField(),
            "paused": false
          })
      } else {
        console.log("No matching document found in preUsers.");
      }
    } catch (error) {
      console.log("Error updating document:", error);
    }
  };


  const handleRejection = async () => {
    try {
      const userRef = doc(db, "users", docId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        await updateDoc(userRef, {
           pendingPitchDeck: deleteField()
          })
      } else {
        console.log("No matching document found in preUsers.");
      }
    } catch (error) {
      console.log("Error updating document:", error);
    }
  };

  const sendApprovalConfirmation = async () => {
    try {
        const userEmail = userData.email
        const name = userData.username
//console.log(JSON.stringify(userData))
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            //name: name,
            email: userEmail
          },
        ],
        templateId: 55,
        params: {
          NAME: name
        },
      };
      const response = await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });

    } catch (error) {
      console.log(error);
    }
  };

  const sendRejectionConfirmation = async () => {
    try {
        const userEmail = userData.email
        const name = userData.username
//console.log(JSON.stringify(userData))
      const payload = {
        sender: {
          name: "PRAIRI",
          email: "invite@prairi.com",
        },
        to: [
          {
            //name: name,
            email: userEmail
          },
        ],
        templateId: 56,
        params: {
          NAME: name
        },
      };
      const response = await axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          Accept: "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      });

    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async () => {
    if (currentUser && isAdminUser) {
      await handleAccept();
      await sendApprovalConfirmation();
      setApproved(true)
    } else {
      navigate("/404");
    }
  };


  const handleReject = async () => {
    if (currentUser && isAdminUser) {
        await handleRejection();
        await sendRejectionConfirmation();
        setRejected(true)
      } else {
        navigate("/404");
      }
  }

  useEffect(() => {
      getDataFromDb();
  }, [docId, isAdminUser, currentUser, navigate]);

if (isAdminUser){
  return (
<div className={`w-full relative`} style={{ marginTop: navBarHeight + 4, minHeight: "100vh" }}>
  {dataFetched && (
    <div className="flex flex-col justify-center items-center mt-4">
      <embed src={newDeck} width="100%" type="application/pdf" height={height} />
      <div className="fixed bottom-0 left-0 w-full flex justify-center pb-4 bg-[#262626]">
        {!approved && !rejected && <PrairiButton click={handleApprove} size="lg" border="green" label={`Approve Deck`} />}
        {!approved && !rejected && <PrairiButton click={handleReject} size="lg" border="red" label={`Reject Deck`} />}
        {approved && (
        <h1 className="my-4 text-center text-lg md:text-xl">
          Deck approved!
        </h1>
      )}
              {rejected && (
        <h1 className="my-4 text-center text-lg md:text-xl">
          Deck rejected!
        </h1>
      )}
      </div>

    </div>
  )}
</div>
  );
}
else{
  return(<>forbidden access</>)
  
}

};

export default DeckApproval;
