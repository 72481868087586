
import React, { useContext, useState, useEffect } from "react";
import { navBarContext, authContext } from "./context/context";
import { useNavigate } from "react-router-dom";
import Settings from "./Settings";
import { query, collection, db, where, getDocs, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import { InvestorProfile, CompleteYourProfile, Loading, InvestorContact, Modal } from "@peterfosso/prairi-components"
import Eye from "../assets/eye.png"

const DataProfileInvestor = (props) => {
    const { navBarHeight } = useContext(navBarContext);
    const { currentUser } = useContext(authContext);
    const [docId, setDocId] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [showPreview, setShowPreview] = useState(false)
    const [currentUserData, setCurrentUserData] = useState(null);
    let navigate = useNavigate();

    const redirectToLogin = () => {
        navigate("/login"); // Redirect to the login page
    };

    const redirectToForms = () => { //search for current position of the user in the forms
        navigate("/investorregistration")
    }

    useEffect(() => {
        if (dataFetched && !currentUser) {
            redirectToLogin();
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            setDocId(currentUser.uid);
        }
    }, [currentUser]);

    const getDataFromUser = async () => {
        try {
          // Get user data from email
          const { email } = currentUser;
          const q = query(collection(db, "users"), where("email", "==", email));
          const querySnapshot = await getDocs(q);
          let userData;
          let userId;
          querySnapshot.forEach((doc) => {
            userData = doc.data();
            userId = doc.id;
          });
          setCurrentUserData(userData)
          return { userData, userId };
        } catch (error) {
          //console.log("error in getDataFromUser");
        }
      };

      const fetchData = async () => {
        try {
          if (currentUser) {
            await getDataFromUser(); // Pass the UID from currentUser
            setDataFetched(true)
    
          }
        } catch (error) {
          //console.log('error fetching data' + error);
        }
      }
    
      useEffect(() => {
        fetchData();
        //console.log("data fetched?" + JSON.stringify(dataFetched))
        //console.log(currentUserData)
    
      }, [currentUser]);

    const [showSettings, setShowSettings] = useState(false);
    const handleSettings = () => {
        setShowSettings(true);
        navigate("/settings");

    };

    if (!dataFetched){
        return (
          <div
          id="newMatch__"
          style={{
            marginTop: navBarHeight ,
            marginBottom: navBarHeight + 30,
            width: "100%",
            minWidth: "366px",
            padding: "2%",
          }}
        >
        <Loading/>
        </div>)
      }
      
      
        return (
      
          <div
            id="newMatch__"
            style={{
              marginTop: navBarHeight ,
              marginBottom: navBarHeight + 10,
              width: "100%",
              minWidth: "366px",
              padding: "2%",
            }}
          >
            <section
              id="newMatch__container"
              className={`
              w-9/12 max-w-[660px] 
      
              m-auto  rounded-md
              py-2.5 px-5 
              min-w-[380px]
             `}
            >
              <div className="flex items-center  mb-2 " onClick={()=>setShowPreview(true)}>
                <img src={Eye} className="w-[20px] mr-1"></img>
              <div className="text-silverPrairi italic text-xs cursor-pointer">Click <span className="underline font-bold">here</span> to view profile as seen by a startup match</div>
              </div>

            {dataFetched && currentUserData.formData && <InvestorProfile investor={currentUserData} onClose={handleSettings}/>}  
            <Modal open={showPreview} transparent close><InvestorContact investor={currentUserData}  dashboard close={()=>setShowPreview(false)}/></Modal>
            </section>
            {dataFetched && <CompleteYourProfile open={!currentUserData.formData || !currentUserData.formData.matching.completed} click={redirectToForms}/>}
          </div>
        );
            }


export default DataProfileInvestor;
