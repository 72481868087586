import FilterStrategy from './FilterStrategy.js';
import {ERR_INVALID_INVESTOR, ERR_INVALID_STARTUP} from './util/error_messages.mjs';
class StageFilter extends FilterStrategy {
    doFilter(investor, startups) {
        let stages = new Set (Object.keys(investor.formData.industry.stage).filter((key) => investor.formData.industry.stage[key]))
       // console.log('Filtering on Stage');
        //console.log('stages: ' + stages)
        this.guard(investor, startups);
        const filteredData = startups.filter(startup => stages.has(startup.formData.industry.stage));
        return filteredData;
    }
}

export default StageFilter;