import React, { useContext } from "react";
import { investorContext } from "../context/context";
import Logo from "../../assets/logostart.png";
import GreenLogo from "../../assets/logostartgreen.png";
const InvestorBreadcrumbLast = (props) => {
  const { globalData } = useContext(investorContext);
  const { currentLocation, formData } = globalData;
  /*let parentDivCrumb = "bg-gray-700";
  let rectangleDivCrumb = "bg-gray-700";*/
  let mobileBottomBorder = "border-b-gray-700";
  let opacity = "opacity-50";
  let logoIcon = Logo;
  let title = props.title.toLowerCase();
  let renderedElement;
  /* let roundedRightRectangle;*/
  if (
    (currentLocation !== title || currentLocation === title) &&
    formData[title].completed
  ) {
    /*parentDivCrumb = "bg-green-700";
    rectangleDivCrumb = "bg-green-700";*/
    mobileBottomBorder = "border-b-green-500";
    opacity = "opacity-70";
    logoIcon = GreenLogo;
  }

  
  switch (title) {
    case "matching":
      renderedElement = logoIcon;
      break;
    default:
      break;
  }
  return (
    <>
      {/* mobile */}
      <div
        className={`md:hidden pb-2 border-b-2  h-10 flex items-center justify-center ${mobileBottomBorder} ${opacity}`}
      >
        <img className="h-full" src={renderedElement} alt="icon" />
      </div>

      {/* Bigger devices */}
      <div className="hidden relative text-center md:flex items-center ">
        <div
        // className={`flex items-center h-10 py-0 pl-2 pr-5 text-sm  ${rectangleDivCrumb} ${roundedRightRectangle} `}
        >
          {/* {props.displayTitle} */}
        </div>
        <img
          className="-top-3 z-5 -right-14 h-8 md:h-16 mb-1 opacity-100"
          src={logoIcon}
          alt="Matching"
        />
      </div>
    </>
  );
};

export default InvestorBreadcrumbLast;
