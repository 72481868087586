import {ERR_INVALID_INVESTOR, ERR_INVALID_STARTUP} from './util/error_messages.mjs'
class FilterStrategy {
    doFilter(investor, startups) {
        throw new Error("ERR_NOT_IMPLEMENTED_FILTER");
    }

    guard(investor, startups){
        if(startups === null || !Array.isArray(startups)) throw new Error(ERR_INVALID_STARTUP);
        if(investor === null) throw new Error(ERR_INVALID_INVESTOR);
    }
}

export default FilterStrategy;