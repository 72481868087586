import React, { useState, useEffect, useContext } from "react";
import { Counter } from "@peterfosso/prairi-components";
import { navBarContext, authContext } from "./context/context";
import { getEventByName, getUsersCountByMonth, getStartupsCountByMonth, getInvestorsCountByMonth, getBuildAStartupCountByMonth } from "../utils";

const Stats = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const isAdminUser =
    currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  const [startupLanding, setStartupLanding] = useState(null);
  const [startupRequest, setStartupRequest] = useState(null);
  const [investorLanding, setInvestorLanding] = useState(null);
  const [investorRequest, setInvestorRequest] = useState(null);
  const [buildBanner, setBuildBanner] = useState(null);
  const [buildJoin, setBuildJoin] = useState(null)
  const [startups, setStartups] = useState(0)
  const [investors, setInvestors] = useState(0)
  const [founders, setFounders] = useState(0)
  const [fetchedData, setFetchedData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const startupLandingData = await getEventByName("Landing Startup");
      const startupRequestData = await getEventByName("Startup Request click")
      const investorLandingData = await getEventByName("Landing Investor");
      const investorRequestData = await getEventByName("Investor Request click")
      const buildBannerData = await getEventByName("Build a Startup banner")
      const buildJoin = await getEventByName("Join Build-A-Startup button")
      const startupCount = await getStartupsCountByMonth("March")
      const investorCount = await getInvestorsCountByMonth("March")
      const foundersCount = await getBuildAStartupCountByMonth("March")
      setStartupLanding(startupLandingData);
      setStartupRequest(startupRequestData);
      setInvestorLanding(investorLandingData);
      setInvestorRequest(investorRequestData);
      setBuildBanner(buildBannerData);
      setBuildJoin(buildJoin)
      setStartups(startupCount)
      setInvestors(investorCount)
      setFounders(foundersCount)
      setFetchedData(true);
    };
    fetchData();
  }, []); // Empty dependency array to run effect only once

  if (!isAdminUser) {
    return <div>Forbidden</div>;
  } else {
    return (
      <div
        id="newMatch__"
        style={{
          marginTop: navBarHeight + 30,
          marginBottom: navBarHeight + 10,
          width: "100%",
          minWidth: "366px",
          padding: "2%",
        }}
        className="flex flex-col justify-center items-center"
      >
        <h1 className="text-2xl mb-5">Stats</h1>
    
        {fetchedData && <div className="flex w-[60%] flex-col items-center bg-[#303030] px-5 pt-2 rounded-lg pb-4">
            <h2 className="mb-4">March</h2>
            <div className="flex w-full justify-between bg-[#303030] ">
          <div className=" border border-yellowPrairi rounded-lg p-4 flex flex-col space-y-4">
            {startupLanding && fetchedData && <Counter event={startupLanding} color="yellow"/>}
            {startupRequest && fetchedData && <Counter event={startupRequest} color="yellow"/>}
            <div>Total requests: {startups}</div>
          </div>
          <div className=" border border-bluePrairi rounded-lg p-4 flex flex-col space-y-4">
            {investorLanding && fetchedData && <Counter event={investorLanding} color="blue"/>}
            {investorRequest && fetchedData && <Counter event={investorRequest} color="blue"/>}
            <div>Total requests: {investors}</div>
          </div>
          <div className=" border border-customWhite rounded-lg p-4 flex flex-col space-y-4">
            {buildBanner && fetchedData && <Counter event={buildBanner} color="white"/>}
            {buildJoin && fetchedData && <Counter event={buildJoin} color="white"/>}
            <div>Total requests: {founders}</div>
          </div>
          </div>
        </div>}
      </div>
    );
  }
};

export default Stats;
