import React from "react";
import { useNavigate } from "react-router-dom";

const Terms = (props) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.toggleTerms();
    navigate("/Settings");
  };
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  // const formattedDate = new Date().toLocaleDateString('en-US', options);
  return (
    <div className='absolute top-0  left-0 w-full h-full'>
      <div
        onClick={props.toggleTerms}
        className="w-full h-full z-5 bg-gray-500 opacity-40"
      ></div>
      <div className="absolute top-0 left-0 right-0 w-full  md:w-8/12 mt-24 mx-auto py-2 pl-4 pr-2  rounded h-5/6 z-10 bg-white text-black flex flex-col justify-between">
        <div>
          <h1 className="font-bold text-lg my-4">Terms of Use

          </h1>
        </div>
        <hr />
        <div className="w-full h-full overflow-y-auto mb-4">
          <h2 className="italic mt-4">Last revision: 19 October 2023</h2>
          <section>
            <h1 className="font-bold text-lg my-4">LEGAL NOTICE</h1>
            <div className="max-full ">
            PRAIRI matches and notifies users based solely on the information that they (the Users) have provided 
            and are not responsible for its accuracy nor are we recommending that the investor invest in any of 
            the startups on our platform nor are we recommending that any of the startups do business with (or 
            receive investment from) any of the investors on our platform. PRAIRI is merely informing its users 
              that another member has matching criteria. 

PRAIRI is not legally responsible if our users wish to do business together.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Who We Are</h1>
            <div className="max-full ">
              PRAIRI ("We", "Us" and "Our") operates the website prairi.com
              ("Website") and platform (collectively the "Service").
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">What We Do</h1>
            <div className="max-full ">
              The Service provides a platform for startup business founders and
              business investors to meet.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Revenue Model</h1>
            <div className="max-full ">
              We reserve the right to
              make changes to our business/revenue model at any time. If an
              Investor decides to do business with a company with whom we've
              made an introduction, the Investor will pay a 'success fee'
              (currently 2%) based on the total amount of investment to be
              received by the startup. This fee is only charged with whom and
              where it is legally allowed, based on each country's regulations.
              
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Terms of Service</h1>
            <div className="max-full ">
              We provide the user of the Service ("You" and "Your") with access
              to the Service on and subject to the following Terms of Service
              ("ToS"), which may be updated by Us at any time without notice to
              You. You should read these ToS carefully before using any part of
              the Service. The most current version of the ToS at any time can
              be found by accessing the "Terms of Service" link on Our Website.
              <br /> <br />
              Please check the ToS every time You wish to use the Service to
              ensure You understand the terms that apply at that time. You are
              also responsible for ensuring that all persons who access the
              Service through Your User account on the Service are aware of
              these ToS and that they comply with them. <br /> <br />
              If You do not agree to the ToS You should immediately exit Our
              Website and cease to use the Service. If You are a registered user
              of Service, You will be required to delete Your profile.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Communication Between You and Us
            </h1>
            <div className="max-full ">
              The easiest way to communicate with Us is to email Us at
              info@prairi.com. If We have to contact You We will do so by
              writing to You at the email address You provided to Us in the
              Registration Data referred to below. When We refer in the ToS to
              "in writing" or "written", this will include e-mail.
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">Account Registration</h1>
            <div className="max-full ">
              You will provide true, accurate, current and complete information
              about You as prompted by the Service account registration form on
              the Website (such information being the "Registration Data"); and
              maintain and promptly update the Registration Data to keep it
              true, accurate and complete. If You provide any Registration Data
              or other information that is untrue, inaccurate or incomplete or
              We have reasonable grounds to suspect that such information is
              untrue, inaccurate or incomplete, We may suspend or terminate Your
              account and refuse any and all current or future use of the
              Service (or any part thereof).
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Account Security</h1>
            <div className="max-full ">
              You are responsible for maintaining the confidentiality of Your
              account and password. You must immediately notify Us of any
              unauthorised use of Your password or account or any other breach
              of security. We do not accept any liability for any loss or damage
              arising from Your failure to comply with this responsiblity.
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">Your Use of The Service</h1>
            <div className="max-full ">
              You understand and agree that all information, data, text,
              software, sound, photographs, graphics, video, messages or other
              materials ("Content"), whether publicly posted or privately
              transmitted, is the sole responsibility of the person from which
              such Content originated. You acknowledge that We do not control
              and may not pre-screen the Content posted by users of the Service
              (including You) and therefore We cannot (and do not) guarantee the
              accuracy, integrity or quality of such Content.
              <br />
              <br />
              You are solely responsible for all Content that You upload,
              transmit or otherwise make available via the Service. You agree to
              comply with all applicable laws regarding the transmission of data
              from the country in which You reside.
              <br />
              <br />
              You agree not to use the Service to upload, post, email, transmit
              or otherwise make available any Content that:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>
                  Is unlawful, harmful, threatening, abusive, harassing,
                  tortious, defamatory, vulgar, obscene, libellous, deceptive,
                  fraudulent, invasive of another's privacy, sexually explicit
                  or pornographic, hateful, or racially, ethnically or otherwise
                  objectionable;
                </li>
                <li>
                  amounts to personal attacks on others or make disparaging
                  remarks about any person or entity and/or stalk or otherwise
                  harass others;
                </li>
                <li>
                  interferes with anyone else's use and enjoyment of the
                  Service;
                </li>
                <li>
                  encourages, incites, solicits or promotes illegal or criminal
                  activities (including market manipulation, pornography,
                  terrorism, explosives, weapons, drugs, programming viruses,
                  computer hacking and copyright infringement)
                </li>
                <li>
                  abuses, threatens, promotes, or instigates physical harm or
                  death;
                </li>
                <li>
                  infringes any patent, trademark, trade secret, copyright or
                  other proprietary rights of any party;
                </li>
                <li>
                  contains software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer software or hardware or
                  telecommunications equipment or to damage or obtain
                  unauthorised access to any data or other information of any
                  third party;
                </li>
                <li>impersonates any person or entity;</li>
                <li>
                  has had identifiers manipulated in order to disguise its
                  origin;
                </li>
              </ul>
              <br />
              <br />
              You agree not to:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>
                  use the Service to upload, post, email, transmit or otherwise
                  make available any unsolicited or unauthorised advertising,
                  promotional materials, "junk mail" or "spam";
                </li>
                <li>
                  interfere with or disrupt the Service or servers or networks
                  connected to the Service;
                </li>
                <li>
                  use the Service to stalk or otherwise harass other users; or
                </li>
                <li>
                  use the Service to collect or store personal data about other
                  users.
                </li>
              </ul>
              <br />
              <br />
              We may remove any Content which violates the ToS or which We deem
              to be offensive, indecent or objectionable. Furthermore, We may
              retain and disclose Content if required to do so by law or in the
              good faith belief that such retention or disclosure is reasonably
              necessary to:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>comply with any legal process;</li>{" "}
                <li>enforce the ToS;</li>
                <li>
                  respond to claims that any Content violates the rights of
                  third-parties; or
                </li>
                <li>
                  protect the rights, property, or personal safety of Us, Our
                  other users or the public.
                </li>{" "}
                <li>
                  We do not guarantee that the Service or any Content will
                  always be available. We may suspend, withdraw or restrict the
                  availability of all or any part of the Service and/or Content
                  for business and/or operational reasons. We will try to give
                  You reasonable notice of any suspension or withdrawal but may
                  do so without notice.
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Viruses and Bugs</h1>
            <div className="max-full ">
              We do not guarantee that the Service will be secure or free from
              bugs or viruses. You are responsible for configuring and
              protecting Your information technology, computer programmes and
              platform to access the Service. You should use Your own virus
              protection software.
              <br />
              <br />
              You agree to not misuse the Service by knowingly introducing
              viruses, trojans, worms, logic bombs or other material that is
              malicious or technologically harmful. You must not attempt to gain
              unauthorised access to the Service, the server on which any part
              of the Service is stored or any server, computer or database
              connected to the Service. You must not attack any part of the
              Service via a denial-of-service attack. By breaching this section,
              Your right to use the Service will cease immediately and You would
              commit a criminal offence. We will be entitled to report any such
              breach to the relevant law enforcement authorities and may
              co-operate with those authorities by disclosing Your identity to
              them.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Our Copyrights, Trademarks and other Proprietary Rights
            </h1>
            <div className="max-full ">
              Our trademarks, service marks and logos are registered and
              unregistered trade marks (the "Trade Marks"). Your use of the
              Service does not grant You a license or right to use Our Trade
              Marks.
              <br />
              <br />
              You acknowledge and agree that the Service and any necessary
              software used in connection with the Service ("Software") contains
              proprietary and confidential information that is protected by
              applicable intellectual property and other laws. You agree not to
              modify the Software in any manner or form, or to use modified
              versions of the Software. You further acknowledge and agree that
              Content contained in information presented to You through the
              Service or by Our partners is protected by copyrights, trademarks,
              service marks, patents or other proprietary rights and laws.
              <br />
              <br />
              Except as expressly authorised by Us (or Our partners in the case
              of their Content), You agree not to copy, reproduce, modify,
              distribute, transmit, publish, license, use, resell, rent, lease,
              loan, all or any part of the Service, the Software or Content
              contained in information presented to You through the Service, or
              create derivative works based on any of the same, in whole or in
              part.
              <br />
              <br />
              We grant You a personal, non-transferable, revocable and
              non-exclusive right and license to:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>
                  Use the Service, provided that such use is in accordance with
                  these ToS;
                </li>{" "}
                <li>
                  print or download extracts of information obtained by You
                  through the Service for Your personal non-commercial use;
                </li>
                <li>
                  copy information obtained by You through the Service for the
                  purpose of sending to individual third parties for their
                  personal non-commercial information, provided that You
                  acknowledge Us as the source of the information and that You
                  ensure that the third parties comply with these conditions as
                  if they were a party to them; and
                </li>
                <li>
                  use Our API to access and extract into Your own systems the
                  relevant data that We from time to time make available for
                  such purpose (" Extraction Data "), and the right to use the
                  Extraction Data for commercial and/or non-commercial purposes,
                  provided that in each case, You agree not to access the
                  Service or otherwise try to extract the Extraction Data, by
                  any means other than through the interfaces that are provided
                  by Us.
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Your Copyrights and Content
            </h1>
            <div className="max-full ">
              We do not own nor claim claim ownership of Content You submit to
              or make available for inclusion on the Service. With respect to
              Content You submit to or make available for inclusion on, the
              Service, via areas of the Service that are:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>
                  Publicly accessible, You grant Us, a worldwide, royalty-free,
                  non-exclusive, perpetual, irrevocable and fully sub-licensable
                  license to use, distribute, reproduce, modify, adapt, publish,
                  translate and display such Content (in whole or in part) and
                  to incorporate such Content into other works in any format or
                  medium now known or later developed; and non-publicly
                  accessible, You grant Us, a worldwide, royalty-free,
                  non-exclusive, irrevocable license to use, distribute,
                  reproduce, modify, adapt, publish, translate and display such
                  Content (in whole or in part) on the Service, solely for the
                  purpose for which such Content was submitted or made
                  available. This license remains in effect for as long as You
                  choose to continue to include such Content on the Service and
                  will terminate when You remove or We remove such Content from
                  the Service. For the purpose of this section, 'publicly
                  accessible' areas of the Service are those areas that are
                  intended by Us to be available to the general public. Publicly
                  accessible areas of the Service would not include portions of
                  the Service intended for private communication.
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Linking to Our Service</h1>
            <div className="max-full ">
              You may link to Our Website, provided You do so in a way that is
              fair and legal and does not damage Our reputation. You must not
              establish a link in such a way as to suggest any form of
              association with, approval of, or endorsement by Us where none
              exists. You must not establish a link to Our Website in any
              website that is not owned by You. Our Website must not be framed
              on any other website. We reserve the right to withdraw linking
              permission at any time. If You wish to link or make use of Content
              on Our Website other than that set out in this section, please
              email us at{" "}
              <a
                className="underline text-blue-700 font-bold"
                href="mailto:info@prairi.com"
              >
                info@prairi.com
              </a>
              <br />
              <br />
              The Service may provide, or third parties may provide, links to
              other World Wide Web sites or resources. These links are provided
              for Your information only. Such links should not be interpreted as
              approval by Us of those links or information You may obtain from
              them. We have no control over the contents of those websites or
              resources and are not responsible for the availability of such
              sites or resources, and do not endorse any Content, advertising,
              products, or other materials on or available from such sites or
              resources. We accept no responsibility or liability, directly or
              indirectly, for damage or loss caused or alleged to be caused by
              or in connection with use of or reliance on, any such any
              information or Content contained in any such site or resource.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Protecting Your Data</h1>
            <div className="max-full ">
              For the purposes of this section, the terms "personal data",
              "controller", and "processing", will have the meanings set out
              under applicable Data Protection Laws. Data Protection Laws means
              EU Directive 95/46/EC as amended, replaced or superseded from time
              to time, including, from 25 May 2018, EU General Data Protection
              Regulation 2016/679 and, to the extent applicable, the data
              protection or privacy laws of any other country.
              <br />
              <br />
              Our Privacy Policy sets out the terms on which We process any
              personal data We collect from You or that You provide to Us.
              <br />
              <br />
              Where You copy material from the Website or Our other Service for
              Your own use, to the extent that such material includes personal
              data, You acknowledge and agree that You will act as controller in
              relation to the personal data which You process and You agree to
              comply with all applicable Data Protection Laws.
              <br />
              <br />
              Other users or third parties may, consistent with Your preferences
              and the information You provide, process, access and/or share Your
              information (including any personal data). To the extent that a
              third party makes such use of Your personal data, You acknowledge
              and agree that they will do so as controller and We will have no
              control over their processing of Your personal data.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Cookies and Analytics</h1>
            <div className="max-full ">
            We use Firebase Analytics, a web analytics service provided by Google, 
            to collect and analyze non-personal and aggregated data about your use 
            of the Website. Firebase Analytics may use cookies and similar 
            technologies to track user interactions. By using the Website, you 
            consent to the use of cookies for analytics purposes. To learn more 
            about how Firebase Analytics collects and processes data, please review 
            Google's Privacy Policy.
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">
              Terminating Your Use of Our Service
            </h1>
            <div className="max-full ">
              You agree that We, in Our sole discretion, may terminate the
              password, account (or any part thereof) or Your use of the
              Service, and remove and discard any Content within the Service,
              for any reason. We may also in Our sole discretion and at any time
              discontinue providing the Service (or any part thereof), with or
              without notice. You agree that any termination of Your access to
              the Service under any provision of this ToS may be effected
              without prior notice, and acknowledge and agree that We may
              immediately deactivate or delete Your account and all related
              information and files in Your account and/or bar any further
              access to such files or the Service. Further, You agree that We
              will not be liable to You or any third-party for any termination
              of Your access to the Service.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Disclaimer</h1>
            <div className="max-full ">
              We take all reasonable care to ensure that information obtained by
              You through the Service is correct, but We are providing the
              Service on an "AS IS" basis and we make no representation or
              warranty of any kind regarding the Service or information obtained
              by You through the Service. You should be aware that information
              obtained by You through the Service, may be incomplete, out of
              date, inaccurate, incorrect or unsuitable for any purpose. It is
              therefore essential that You verify all such information or take
              appropriate professional advice before taking any action in
              reliance upon it.
              <br />
              <br />
              You expressly understand and agree that Your use of the Service is
              at Your sole risk and that the Service is provided on an "AS IS"
              and "AS AVAILABLE" basis. We expressly disclaim all warranties of
              any kind, whether express or implied. Any material downloaded or
              otherwise obtained through use of the Service is done at the sole
              discretion and risk of You and You will be solely responsible for
              any damage to its computer system or loss of data that results
              from the download of any such material. No advice or information,
              whether oral or written, obtained by You from Us or through or
              from the Service will create any warranty not expressly stated in
              these ToS. <br />
              <br />
              You understand, acknowledge and agree that the Service is designed
              as an information and networking tool only, and that We are not
              engaged in providing, nor are We representing ourselves as
              providing legal, tax, financial, accounting, or other professional
              advice or opinions of any nature whatsoever to You. <br />
              <br />
              Information obtained by You through the Service is not intended to
              constitute a financial promotion for the purposes of the Financial
              Services and Markets Act 2000. In addition, information obtained
              by You through the Service does not amount to a recommendation or
              advice by Us, on the merits of any transaction or service. We are
              therefore not responsible for providing You with the protection
              which would ordinarily be afforded to clients by FCA regulated
              firms and You should seek Your own legal, investment and tax
              advice before acting on information obtained through the Service.
              <br />
              <br />
              You understand, acknowledge and agree that information obtained
              through the Service is for information purposes only and not
              investment advice and that it is Your own responsibility to obtain
              independent legal, tax, financial, accounting, or other
              professional advice with respect to evaluating, agreeing,
              establishing and/or implementing any relationship or investment
              based on any of the information obtained via the Service.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Your Indemnity</h1>
            <div className="max-full ">
              You agree that You are responsible for and will pay to Us, Our
              officers, directors, employees, agents, licensors and suppliers
              all amounts which cover all losses, expenses, damages and costs,
              including legal fees reasonably incurred, resulting from any
              violation of these ToS or any activity related to Your account
              (including negligent or wrongful conduct), by You or any other
              person accessing Our Website using Your account.
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">
              Limitation of Our Liability
            </h1>
            <div className="max-full ">
              Your Permitted Use of Our Service is allowed on the condition that
              you accept and abide by these terms
              <br />
              <br />
              We do not exclude or limit in any way our liability to You where
              it would be unlawful to do so. This includes liability for death
              or personal injury caused by Our negligence or the negligence of
              Our employees, agents or subcontractors and for fraud or
              fraudulent misrepresentation.
              <br />
              <br />
              Subject to this, Our liability to You or any third party, and the
              liability of our affiliates, officers, directors, agents and
              employees, in any circumstance is limited to one hundred euros
              (100 EUR).
              <br />
              <br />
              We will not be liable for:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>loss of profits, sales, business, or revenue;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>
                  loss of business opportunity, goodwill or reputation; or
                </li>{" "}
                <li>any indirect or consequential loss or damage.</li>
              </ul>
              <br />
              <br />
              Subject to section 16.2, We exclude all implied conditions,
              warranties, representations or other terms that may apply to the
              Service (including any Content submitted to or made available on,
              the Service) and we will not be liable to You for any loss or
              damage, whether in contract, tort (including negligence), breach
              of statutory duty, or otherwise, even if foreseeable, arising
              under or in connection with:
              <br />
              <br />
              <ul className=" list-disc pl-4 list-inside">
                <li>the use or the inability to use the Service; or</li>
                <li>
                  the use of or reliance on any Content obtained through the
                  Service; or
                </li>
                <li>
                  any agreement, understanding or relationship You enter into
                  with any other person or entity with whom You have been put in
                  contact through the Service.
                </li>
              </ul>
              <br />
              <br />
              Subject to section 16.2, You understand, acknowledge and agree
              that We do not (in any manner whatsoever) endorse other users of
              the Service or information or pitch decks presented to You by such
              users. We will not be held liable for any claims of any nature
              whatsoever arising or resulting from any information or pitch deck
              of any of other users.
              <br />
              <br />
              The exclusions and restrictions of liability contained in these
              ToS are considered reasonable by You.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Jurisdictional Exclusions and Limitations
            </h1>
            <div className="max-full ">
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation or exclusion of certain liabilities.
              Accordingly, some of the above limitations of sections 14 and 16
              may not apply to You.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Other Relevant Information
            </h1>
            <div className="max-full ">
              Each of the provisions contained in these ToS will be construed as
              being independent of every other and if any provision contained in
              the ToS is determined to be invalid or unenforceable pursuant to
              applicable law, such determination will not affect the validity of
              the remainder of these terms which will continue in effect with
              the invalid provision deemed deleted.
              <br />
              <br />
              The Service is operated and controlled from the EU and these terms
              and Your use of the Service is governed by and construed in
              accordance with the laws of England and Wales.
              <br />
              <br />
              These ToS (and any documents referred to herein) constitute the
              entire agreement between You and Us and govern the use of the
              Service by You, superseding any prior agreements between You and
              Us. You also may be subject to additional terms that may apply
              when You use affiliate services, third-party content or
              third-party software. The failure of Us to exercise or enforce any
              right or provision of the ToS, will not constitute a waiver of
              such right or provision. If any provision of the ToS is found by a
              court of competent jurisdiction to be invalid, the parties
              nevertheless agree that the court should endeavour to give effect
              to the parties' intentions as reflected in the provision, and the
              other provisions of the ToS remain in full force and effect. The
              section titles in these ToS are for convenience only and have no
              legal or contractual effect.
            </div>
          </section>
        </div>
        <div>
          <hr />
          <input
            type="button"
            className=" bg-blue-500 mt-4 cursor-pointer rounded border border-gray-500 p-2.5  text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
            value="Close"
            data-testid="terms"
            onClick={props.toggleTerms}
          ></input>
          {/**This button is not functional yet. When functionality is added, please uncomment the part of the test associated to it */}
        </div>
      </div>
    </div>
  );
};

export default Terms;
