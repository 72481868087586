import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { investorContext, authContext } from "./context/context";
import EyeIcon from "../assets/eyeicon.png";
import { getAnalytics, logEvent } from 'firebase/analytics';
import BalanceIcon from "../assets/balanceicon.png";
import TeleIcon from "../assets/teleicon.png";
import WarningIcon from "../assets/warning_icon3.png";
import { updateLatestActions } from "../utils";
import Heart from "../assets/heart.png";
import { useMediaQuery } from "react-responsive";
import Handshake from "../assets/handshake.png"
import { navBarContext } from "./context/context";
import { analytics } from "../firebase/firebaseApp";
import { query, collection, db, where, getDocs, getDoc, doc, updateDoc, Timestamp } from "../firebase/firebaseApp";

const StartupWelcome = () => {
  
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const [renderedContent, setRenderedContent] = useState(0);
  const [goDataProfile, setGoDataProfile] = useState(false);
  const { currentUser } = useContext(authContext);
  const [docId, setDocId] = useState(null);
  const time = Timestamp.fromDate(new Date())
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const storeView = async (userId, slide) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, `view slide ${slide}`, isMobile)
  }



  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      setDocId(userId)
      return { userData, userId };
    } catch (error) {
      //console.log(error);
    }
  };

  getDataFromDb();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const updateRenderedContent = () => {
    if (renderedContent < 4) {
      setRenderedContent(renderedContent + 1);
      storeView(docId, renderedContent+2)
    } else {
      setRenderedContent(4);
      storeView(docId, "final")
      setGoDataProfile(true);
      setTimeout(() => {
        navigate("/startupregistration");
      }, 2000);
    }
  };

  const contents = [
    {
      number: 1,
      image: EyeIcon,
      alt: "Eye Icon",
      buttonText: "Next",
      content: (
        <>
          <p>
            In order to be matched with investors, you will now create your
            <span className="uppercase font-bold"> Data Profile.</span>
          </p>
          <br />
          <p>
            Data Profiles are hidden from the public and only viewable by
            Investors when there's a match.
          </p>
        </>
      ),
    },
    {
      number: 2,
      image: BalanceIcon,
      alt: "Balance Icon",
      buttonText: "Next",
      content: (
        <p>
          Once your Data Profile is created, PRAIRI's proprietary algorithm will
          analyze and compare your criteria with current (and future) Investor
          profiles.
        </p>
      ),
    },
    {
      number: 3,
      image: TeleIcon,
      alt: "Tele Icon",
      buttonText: "Next",
      content: (
        <p>
          Whenever there's a match, the Investor is invited to view your profile
          and then to contact you if interested.
        </p>
      ),
    },
    {
      number: 4,
      image: Heart,
      alt: "Heart Icon",
      buttonText: "I agree",
      content: (
        <p>

          <p className="mb-3"><span className="uppercase font-bold">Important: </span>Since PRAIRI is here to help startups, all we ask is that you: </p>
          <ul className="list-disc mb-3">
            <li><span className="ml-4">Keep your profile current, and</span></li>
            <li><span className="ml-4">When you find funding you will pause your profile [see ‘Settings’].</span></li>
          </ul>
        </p>
      ),
    },
    {
      number: 5,
      image: Handshake,
      alt: "Handshake Icon",
      buttonText: "I understand",
      content: (
        <p>
          <p className="mb-3">LEGAL NOTICE: </p>
          <p>Other than validating a user’s email, phone number, and a quick check-up, it’s you, the user, who is responsible
            to do your own ‘due diligence’ and to thoroughly check out those with whom you may be doing business, just as if these introductions happened at a tech event or over a social media platform.</p>
        </p>
      ),
    }
  ];
  

  return (
    <div
      className={` w-full md:max-w-2xl md:mx-auto border-x-2 border-backgroundPrairi rounded-xl bg-lighterGray  from-navStart to-navEnd  py-4 px-1 my-12`}
      style={{ marginTop: navBarHeight + 8 ,
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.75)",
        borderRadius: "60px"}}
    >
      <h1 className="text-center text-3xl md:text-3xl mt-4 mb-8 font-bold text-textPrairi">
        Next step
      </h1>
      {/* <div className="text-xl my-4 border-2 border-customWhite bg-body w-10 mx-auto rounded-full flex items-start justify-center">
        {contents[renderedContent].number}
      </div> */}
      <div className="w-full flex flex-col items-center text-textPrairi text-lg md:text-md my-4">
        <div className="w-3/12 ">
          <img
            className="w-full mb-6"
            src={contents[renderedContent].image}
            alt={contents[renderedContent].alt}
          />
        </div>
        <div className=" mx-2 w-9/12">{contents[renderedContent].content}</div>
      </div>
      <div className="text-center">
      {renderedContent < 4 &&
          <button
            onClick={updateRenderedContent}
            className="py-1.5 px-3 text-lg mt-3 mb-4 text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
          >
            {contents[renderedContent].buttonText}
          </button>
        }
                {renderedContent === 4  &&
          <div className="flex justify-center items-center">
            <button
              onClick={updateRenderedContent}
              className="py-1.5 px-3 text-lg mt-3 mb-4 text-center rounded-lg bg-backgroundPrairi text-textPrairi border-2 border-greenPrairi"
            >
              I understand
            </button>

          </div>
        }
      </div>
      {goDataProfile && (
        <div>
          <p className="mt-8 text-silverPrairi text-xl text-center ">
            Ok, it's time now to create your Data Profile...
          </p>
        </div>
      )}
    </div>
  );
};

export default StartupWelcome;
