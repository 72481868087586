import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { db, getDoc, doc } from "../firebase/firebaseApp";
import { navBarContext } from "./context/context";
import { useMediaQuery } from "react-responsive";

const Pitch = () => {
  const { userId } = useParams();
  const [pitchUrl, setPitchUrl] = useState(null);
  const { navBarHeight } = useContext(navBarContext);
  const [height, setHeight] = useState(window.innerHeight);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "users", userId);
        const docData = await getDoc(docRef);
        if (docData.exists()) {
          setPitchUrl(docData.data().formData.company.deck);
          console.log(pitchUrl);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocumentData();
  }, [userId]);

  if (!pitchUrl) {
    return <div>Cargando...</div>;
  }

  return (
    <div style={{ marginTop: navBarHeight}} className='w-full '>
      <embed src={pitchUrl} width="100%" height={height} type="application/pdf" 
/>
    </div>
  );

};

export default Pitch;
