import React, { useState, useEffect, useContext } from "react";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import OwnershipLogo from "../../assets/ownership.png";
import { investorContext, authContext } from "../context/context";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions } from "../../utils";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import NextButton from "./../common/NextButton/NextButton";
import CurrencyFormat from "react-currency-format";
import Slider from "rc-slider";
const InvestorOwnership = (props) => {
  const { setDataFunc, globalData } = useContext(investorContext);
  const ownershipData = globalData.formData.ownership;
  const [userRef, setUserRef] = useState([])
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { currentUser } = useContext(authContext);
  const [data, setData] = useState({
    capitalization: ownershipData.capitalization || "",
    traction: ownershipData.traction || "",
    expectation: ownershipData.expectation || "",
    minimum: ownershipData.minimum || "",
    error: "",
    loading: false,
    message: "",
  });
  const [showExpectation, setShowExpectation] = useState(false);
  const [showMinimum, setShowMinimum] = useState(false);
  const currency = globalData.formData.industry.currency;
  useEffect(() => {
    let currentTractions = Object.keys(data.traction).filter(
      (key) => data.traction[key]
    );
    if (
      currentTractions.includes("No users/clients yet") ||
      currentTractions.includes("Users/Clients but no revenue")
    ) {
      setShowExpectation(true);
    } else {
      setShowExpectation(false);
    }
  }, [data]);

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }

    }

fetchData()
  }, [currentUser])

  useEffect(() => {
    let currentTractions = Object.keys(data.traction).filter(
      (key) => data.traction[key]
    );
    if (currentTractions.includes("Revenue exists")) {
      setShowMinimum(true);
    } else {
      setShowMinimum(false);
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();

  const capitalizationSelectOptions = [
    { name: "One founder owns 100%", value: "One founder owns 100%" },
    { name: "2 founders own shares", value: "2 founders own shares" },
    { name: "3 or more founders own shares", value: "3 or more founders own shares" },
    {
      name: "Founders own shares and investors own shares",
      value: "Founders own shares and investors own shares",
    },
  ];
  const tractionSelectOptions = [

    { name: "No users/clients yet", value: "No users/clients yet" },
    {
      name: "Users/Clients but no revenue",
      value: "Users/Clients but no revenue",
    },
    { name: "Revenue", value: "Revenue exists" },
  ];
  const revenueSelectOptions = [
    { name: "Less than 3 Months", value: "Less than 3 Months" },
    { name: "3-6 Months", value: "3-6 Months" },
    { name: "6-12 Months", value: "6-12 Months" },
    { name: "1-2 Years", value: "1-2 Years" },
    { name: "Over 2 Years", value: "Over 2 Years" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    let capitalizations = [];
    let tractions = [];
    capitalizations = Object.keys(capitalization).filter(
      (key) => capitalization[key]
    );
    tractions = Object.keys(traction).filter((key) => traction[key]);
    if (capitalizations.length < 1 || tractions.length < 1) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });
      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            ownership: {
              ...globalData.formData.ownership,
              capitalization,
              traction,
              expectation,
              minimum,
              completed: true,
            },
          },
        });
        updateLatestActions(userRef, "Saved Ownership form", isMobile)
        navigate("/investorregistration/support");
      }, 2000);
    }
  };

  const { capitalization, traction, expectation, minimum, error, message } =
    data;
  return (
    <div className="shadow-grey w-[90%]  rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <form className=" " onSubmit={handleSubmit}>
        <div className="my-2 ">
          <div className="flex items-end pl-3 md:pl-0">
            <div
              className={` h-10 md:h-10 flex items-center justify-center mr-2 md:mr-4 `}
            >
              <img className=" h-full  " src={OwnershipLogo} alt="Company" />
            </div>
            <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
              Capitalization
            </h1>
          </div>
          {capitalizationSelectOptions.map((selection) => {
            return (
              <div
                key={selection.value}
                className="mt-3 px-3 md:pl-24 flex items-center "
              >
                <Switch
                  onChange={() =>
                    setData({
                      ...data,
                      capitalization: {
                        ...capitalization,
                        [selection.value]: !capitalization[selection.value],
                      },
                    })
                  }
                  checked={capitalization[selection.value]}
                  id={selection.value}
                />
                <label htmlFor={selection.value} className="ml-5">
                  {selection.name}
                </label>
              </div>
            );
          })}
        </div>
        <div className="my-4 ">
          <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white uppercase font-bold">
            Traction
          </h1>
          {tractionSelectOptions.map((selection) => {
            return (
              <div
                key={selection.value}
                className="mt-3 px-3 md:pl-24 flex items-center "
              >
                <Switch
                  onChange={() =>
                    setData({
                      ...data,
                      traction: {
                        ...traction,
                        [selection.value]: !traction[selection.value],
                      },
                    })
                  }
                  checked={traction[selection.value]}
                  id={selection.value}
                />
                <label htmlFor={selection.value} className="ml-5">
                  {selection.name}
                </label>
              </div>

              
            );
          })}
          {showMinimum && (
            <div className="my-5 ">
              <div className="">
                <div className="mt-5 px-3 md:pl-24">
                  <h1 className="my-5">
                    The <span className="underline font-bold">minimum</span>{" "}
                    annual revenue you'll accept [optional]
                  </h1>
                </div>
                <div className="mt-5 px-3 md:pl-24">
                  <div className="flex items-center">
                    <div className="w-9/12  ml-2">
                      {currency === "SEK" && (
                        <Slider
                          step={10000}
                          min={0}
                          max={50000000}
                          allowCross={false}
                          defaultValue={0}
                          value={minimum}
                          onChange={(minimum) => setData({ ...data, minimum })}
                          trackStyle={[
                            { backgroundColor: "#00b0f0", height: "6px" },
                          ]}
                          railStyle={{ backgroundColor: "grey", height: "6px" }}
                          handleStyle={[
                            {
                              height: "15px",
                              width: "15px",
                              borderColor: "grey",
                              boxShadow: "none",
                              borderWidth: "0px",
                              borderRadius: "2px",
                              backgroundColor: "grey",
                              outline: "none",
                              opacity: 1,
                            },
                          ]}
                        />
                      )}
                      {currency !== "SEK" && (
                        <Slider
                          step={1000}
                          min={0}
                          max={5000000}
                          allowCross={false}
                          defaultValue={0}
                          value={minimum}
                          onChange={(minimum) => setData({ ...data, minimum })}
                          trackStyle={[
                            { backgroundColor: "#00b0f0", height: "6px" },
                          ]}
                          railStyle={{ backgroundColor: "grey", height: "6px" }}
                          handleStyle={[
                            {
                              height: "15px",
                              width: "15px",
                              borderColor: "grey",
                              boxShadow: "none",
                              borderWidth: "0px",
                              borderRadius: "2px",
                              backgroundColor: "grey",
                              outline: "none",
                              opacity: 1,
                            },
                          ]}
                        />
                      )}
                    </div>
                    <div className="ml-4 font-bold flex items-center">
                      <CurrencyFormat
                        className="ml-2"
                        displayType="text"
                        thousandSeparator={true}
                        value={minimum}
                      />
                      {((minimum === 50000000 && currency === "SEK") ||
                        (minimum === 5000000 && currency !== "SEK")) && (
                        <span className="ml-0">+</span>
                      )}
                      {((minimum === 100000000 && currency === "SEK") ||
                        (minimum === 10000000 && currency !== "SEK")) && (
                        <span className="ml-0">+</span>
                      )}
                      <span className="ml-2 ">{currency.toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {showExpectation && (
          <div className="my-4 ">
            <h1 className=" text-base pl-3 md:pl-24 md:text-xl text-white font-bold">
              REVENUE EXPECTATIONS <span className="text-sm font-normal">[optional]</span>
            </h1>
            {revenueSelectOptions.map((selection) => {
              return (
                <div
                  key={selection.value}
                  className="mt-3 px-3 md:pl-24 flex items-center "
                >
                  <Switch
                    onChange={() =>
                      setData({
                        ...data,
                        expectation: {
                          ...expectation,
                          [selection.value]: !expectation[selection.value],
                        },
                      })
                    }
                    checked={expectation[selection.value]}
                    id={selection.value}
                  />
                  <label htmlFor={selection.value} className="ml-5">
                    {selection.name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}
        <div className="flex justify-center my-5 px-10 md:pl-24">
          <NextButton value="SAVE" />
        </div>
      </form>
    </div>
  );
};

export default InvestorOwnership;
