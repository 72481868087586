import { navBarContext, authContext } from "./context/context";
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Bubble from "../assets/investorcontactbubble.png";
import { useMediaQuery } from "react-responsive";
import {
  InvestorContact,
  Modal,
  PrairiButton,
} from "@peterfosso/prairi-components";
import UnicornBubble from "../assets/investorcontactbubble.png";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "../firebase/firebaseApp";
import { phoneTrackingInvestor, emailTrackingInvestor } from "../utils";

const InvestorInterestContact = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let { investorId } = useParams();
  const [startupData, setStartupData] = useState(null);
  const [investorData, setInvestorData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [unicornPopup, setUnicornPopup] = useState(true);
  const [currentUserId, setCurrentUserId] = useState("");
  let navigate = useNavigate();

  const redirectTo404 = () => {
    navigate("/404");
  };

  const redirectToDashboard = () => {
    navigate("/dashboard");
  };

  //console.log("investor Id" + investorId)

  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userId;
      querySnapshot.forEach((doc) => {
        userId = doc.id;
      });
      setCurrentUserId(userId);
    } catch (error) {
      //console.log(error);
    }
  };

  const getInvestorData = async (investorId) => {
    try {
      const investorDocRef = doc(collection(db, "users"), investorId);
      const investorDocSnap = await getDoc(investorDocRef);

      if (investorDocSnap.exists()) {
        const userData = investorDocSnap.data();
        setInvestorData(userData);
      } else {
        //console.log(`Investor document with ID ${investorId} not found`);
      }
    } catch (error) {
      console.error("Error fetching investor data:", error);
    }
  };

  const fetchData = async (investorId) => {
    try {
      await getInvestorData(investorId);
      await getDataFromUser(currentUser.uid);
      setDataFetched(true);
      // console.log(`investor data: ${JSON.stringify(investorData)}`)
    } catch (error) {
      // console.log("data NOT fetched")
    }
  };

  useEffect(() => {
    fetchData(investorId);
  }, [investorId]);

  return (
    <div
      id="newMatch__"
      style={{
        marginTop: navBarHeight + 4,
        marginBottom: navBarHeight + 10,
        width: "520px",
        minWidth: "366px",
        padding: "4%",
        paddingTop: "6%",
      }}
    >
      {dataFetched && (
        <InvestorContact
          investor={investorData}
          close={redirectToDashboard}
          phoneTracking={() =>
            phoneTrackingInvestor(currentUserId, investorId, isMobile)
          }
          emailTracking={() =>
            emailTrackingInvestor(currentUserId, investorId, isMobile)
          }
        />
      )}

      <Modal border="silver" open={unicornPopup}>
        <div className="p-2">
          <img src={UnicornBubble} />
          <p className="text-sm mt-3">
            ...so we recommend that you wait 24-48 hours before contacting them.
            After that, feel free to contact them and remember to mention that
            you matched on PRAIRI.
          </p>
          <div className="flex justify-center mt-2">
            <PrairiButton
              label="OK"
              textColor="white"
              click={() => setUnicornPopup(false)}
              border="silver"
              rounded="soft"
              size="sm"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvestorInterestContact;
