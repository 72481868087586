import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextButton, Bullet, NotValid, PrairiButton } from "@peterfosso/prairi-components";
import { updateButtonClickCount, generateRandomString } from "../utils";
import PhoneImage from "../assets/startupmatch.png";
import Join from "../assets/join.png";
import { loadStripe } from "@stripe/stripe-js";
import { useMediaQuery } from "react-responsive";
import Invitation from "./Invitation";
import { collection, db, query, where, getDocs, updateDoc, doc, Timestamp, addDoc, getDoc } from "../firebase/firebaseApp";


const Home = (props) => {
  const {
    homeProps: { toggleCodeMenu },
    category,
  } = props;

  
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(null);
  const [code, setCode] = useState("");

  const [validPopup, setValidPopup] = useState(false)
  const [inputCode, setInputCode] = useState("")
  const [codeStatsId, setCodeStatsId] = useState(null);

  const stripePromise = loadStripe(
    "pk_live_51P3Ify00vN5ANT6HieNrKMlp3npEccqmc0E85fOyxSGeI5PE5W2OLeiCXny2pGY8sRSCnG0vj22pMX1XFEoRs3Uu00y9nYHwha"
  );
  const [message, setMessage] = useState(false);
  
  

  const [promoCodes, setPromoCodes] = useState([]);
  const [error, setError] = useState(false);
  const [remainingTries, setRemainingTries] = useState(() => {
    const storedTries = parseInt(localStorage.getItem("remainingTries"), 10);
    return isNaN(storedTries) ? 3 : storedTries;
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchPromoCodes = async () => {
      const promoCodesCollection = collection(db, 'promoCodes');
      const snapshot = await getDocs(promoCodesCollection, { fromCache: false });
      const promoCodesList = snapshot.docs.map(doc => doc.data().code);
      setPromoCodes(promoCodesList);
      //console.log('Promo Codes:', promoCodesList); 
    };
    fetchPromoCodes();
  }, []);

  //Handle input value change
  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleGoClick = async () => {
    const promoCode = promoCodes.find(code => code === inputCode);
    if (promoCode) {
      const docRef = await addDoc(collection(db, "codeStats"), {
        code: inputCode,
        inputAt: Timestamp.fromDate(new Date()),
        used: false
      });
      try {
        const q = query(collection(db, "promoCodes"), where("code", "==", inputCode))
        const querySnapshot = await getDocs(q);
        const docId = querySnapshot.docs[0].id;
        let codeRef = doc(db, "promoCodes", docId)
        const docData = (await getDoc(codeRef)).data();
        await updateDoc(codeRef, {users: docData.users+1})
        setCodeStatsId(docRef.id);
        navigate(`/invitation/${docRef.id}`)
      }
      catch (error) {
        console.log(error)
      }
    }
    else{
      setValidPopup(true)
    }
  }

  const handleRequestStartup = (category) => {
    if (category === "startup") {
      updateButtonClickCount("Startup Request click");
    }
    if (category === "investor") {
      updateButtonClickCount("Investor Request click");
    }
    navigate(`/invitation/${category}`);
  };

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("remainingTries", remainingTries.toString());
  }, [remainingTries]);

  useEffect(() => {
    if (remainingTries === 0) {
      setDisabled(true);
      // setError("Sorry, but to protect PRAIRI and its users, we restrict the number of attempts. You can try again in one hour.");
      const timer = setTimeout(() => {
        setRemainingTries(3);
        setDisabled(false);
      }, 5000); // 1 hour in milliseconds
      return () => clearTimeout(timer);
    }
  }, [remainingTries]);



  if (category === "investor") {
    return (
      <div className="absolute inset-0 z-20 max-w-screen max-w-[100vw]">
        <div
          onClick={toggleCodeMenu}
          className="w-full h-full bg-invitation   bg-cover bg-center opacity-100"
        ></div>
        <div
          data-testid="testHome"
          className="z-50 absolute top-[6vh] md:top-[9vh] left-0 right-0 bg-[#1e1e1d]   md:max-w-lg mx-auto rounded-3xl mt-2 py-5 px-7  text-white"
        >
          <div className="flex justify-end text-sm ">
            <TextButton color="yellow" click={toggleCodeMenu} label="Close" />
          </div>
          <div className="flex flex-col items-center">
            <h1 className=" text-center text-3xl ">Welcome to</h1>
            <h1 className="text-3xl font-bold mb-5">P R A I R I</h1>
          </div>
          <div>
            <Bullet text="You'll be matched and notified when a startup exactly matches your criteria." />
            <br></br>
            <Bullet text="All profiles are private and hidden from the public." />
            <br></br>
            <Bullet
              text={
                <span>
                  There is <strong>nothing to search</strong> on PRAIRI. We do
                  all of that for you so you can instead spend the time making
                  more deals and growing your portfolio.
                </span>
              }
            />
            <br></br>
          </div>
          <div className="text-lg font-bold mb-5 justify-center flex">
            <PrairiButton
              label="REQUEST TO JOIN"
              border="green"
              bgColor="greenBg"
              textColor="blackText"
              rounded="full"
              size="xl"
              click={() => handleRequestStartup(category)}
            />
          </div>

        </div>
      </div>
    );
  }
  if (category === "startup") {
    return (
      <div className="">
      <div className="absolute inset-0 z-20 max-w-screen max-w-[100vw]">
        <div
          onClick={toggleCodeMenu}
          className="w-full h-full lg:h-[118%] bg-invitation bg-cover bg-center opacity-100"
        >        {!isMobile && <div className="absolute lg:w-1/2 w-full px-2 lg:bottom-[-90px] bottom-[120px] left-1/2 transform -translate-x-1/2 text-xs">*NOTE: How many matches you receive depends on your startup’s criteria. Lifetime Membership strictly refers to our service of matching you, as a startup, to investors, at the time(s) you are seeking funding.
        <br/><br/>
        PRAIRI reserves the right to expand its services and offerings at any time and to charge for them accordingly, however, you will, of course, not be required to purchase them. </div>}</div>
        <div
          data-testid="testHome"
          className="z-50 absolute top-[6vh] md:top-[9vh] left-0 right-0 bg-[#1e1e1d]   md:max-w-lg mx-auto rounded-3xl mt-2 pt-5 pr-7  text-white"
        >
          <div className="flex justify-end text-sm mb-5">
            <TextButton color="yellow" click={toggleCodeMenu} label="Close" />
          </div>

          <div>
            <div className="pl-7 mb-2">
              <Bullet
                text="You’ll be matched with investors who are looking for a
      startup like yours, based on your Industry, Location, Stage
      of Development, Investment Amount, etc."
              />
              <br></br>
              <Bullet text="Gain valuable feedback from investors" />
              <br></br>
              <Bullet text="Invitation to our optional online global pitch sessions" />
              <br></br>
              <Bullet text="Plus free tools to grow your startup" />
              <br></br>
            </div>
            <div className={`flex ${isMobile ? "" : ""}`}>

              <img src={PhoneImage} alt="Phone image" className={`rounded-xl ${!isMobile && "w-[295px] h-[392px]"} ${isMobile && "w-[255px] h-[341px]"}`}></img>
              <div className={`${!isMobile && "mt-5"} `}>
                <div className="flex flex-col items-center ">
                  <img src={Join} className={`${!isMobile && "w-[120px]"} ${isMobile && "w-[95px]"}`}></img>
                </div>
                <div className="flex flex-col justify-center items-center text-center lg:text-lg text-sm mt-5 lg:w-[110%]">
                  <div >*<span className="font-bold">Lifetime Membership</span></div>
                  <div className="text-sm">now for a one-time fee of just <span className="font-bold">$100</span> (USD)</div>
                </div>

                <div className="text-center self-end ">
                  <input
                    type="button"
                    className={`uppercase bg-greenPrairi cursor-pointer w-12 h-12 mt-2 text-[black] border-gray-500 border rounded-full text-sm ml-2 outline-none ${
                      disabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    value="Enter"
                    onClick={()=>navigate("/invitation/stripe")}
                    disabled={disabled}
                  ></input>
                </div>
                <div className="flex flex-col text-center items-center lg:mt-6 mt-2 text-xs lg:text-sm">
                  Or if you have a <span className="font-bold">promo code,</span> enter it here
                  <div classname={isMobile && "flex"}>
                  <input type="text" className="w-[80px] lg:w-[100px] h-[20px] lg:h-[30px] lg:mt-2 rounded-md text-[black] bg-customWhite" value={inputCode}
          onChange={e => setInputCode(e.target.value)}></input>
                  <input
                    type="button"
                    className={`uppercase  cursor-pointer w-8 h-8 my-2  border-gray-500 border rounded-full text-sm ml-1 outline-none cursor-not-allowed bg-[#000000] text-[white]${
                      inputCode!=="" ? " bg-greenPrairi text-black"  : ""
                    }`}
                    value="Go"
                    disabled={inputCode===""}
                    onClick={handleGoClick}
                  ></input>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div className="flex flex-col justify-center items-center"><NotValid click={()=>setValidPopup(false)} open={validPopup}/>

        </div>
        
      </div>
      </div>
    );
  }
};

export default Home;