import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import Switch from "react-switch";
import FoundersLogo from "../../assets/founders.png";
import { CountryDropdown } from "react-country-region-selector";
import { dataContext, authContext } from "../context/context";
import NextButton from "./../common/NextButton/NextButton";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, getDataFromUser } from "../../utils";
const Founders = (props) => {
  const { setDataFunc, globalData } = useContext(dataContext);
  const { currentUser } = useContext(authContext);
  const [userRef, setUserRef] = useState([])
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const foundersData = globalData.formData.founders;
  const [data, setData] = useState({
    founders: foundersData.founders || "",
    //: foundersData.female || "",
    generatedFields: foundersData.generatedFields || "",
    nonfounder: foundersData.nonfounder || "",
   // femalemembers: foundersData.femalemembers || "",
    diversity: foundersData.diversity || "",
    error: "",
    loading: false,
    message: "",
  });
  let navigate = useNavigate();
  const foundersNumberSelectOptions = ["0", "1", " 2", "3+"];
  const diversitySelectOptions = [
    {name: "Woman", value: "woman"},
    {name: "POC (person of color)", value: "POC"},
    {name: "LGBTQ+", value: "LGBTQ+"},
    {name: "Immigrant", value: "immigrant"},
    {name: "Person with disabilities", value: "person with disabilities"},
    {name: "Senior/65+ entrepreneur", value: "senior/65+"},
    {name: "Teen/19- entrepreneur", value: "teen/19-"},
  ]

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])


  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
   // console.log(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCountryInput = (groupName, index, countryName) => {
    const allFields = generatedFields[groupName];
    allFields[index].fieldValue2 = countryName;
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };
  const handleDynamicInput = (e, groupName, index, fieldName) => {
    const allFields = generatedFields[groupName];
    allFields[index][fieldName] = e.target.value;
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };
  const addField = (groupName) => {
    // all fields
    const allFields = generatedFields[groupName];
    const newFieldName1 = `field07${allFields.length}`;
    const newFieldName2 = `field08${allFields.length}`;
    const newFieldName3 = `field09${allFields.length}`;
    const newField = {
      fieldName1: newFieldName1,
      fieldValue1: "",
      fieldName2: newFieldName2,
      fieldValue2: "",
      fieldName3: newFieldName3,
      fieldValue3: "",
    };
    allFields.push(newField);
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };
  const closeField = (groupName, index) => {
    const allFields = generatedFields[groupName];
    allFields.splice(index, 1);
    setData({
      ...data,
      generatedFields: {
        ...data.generatedFields,
        [groupName]: [...allFields],
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    if (!founders  || !nonfounder ) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });

      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            founders: {
              ...globalData.formData.founders,
              founders,
              //female,
              generatedFields,
              nonfounder,
              //femalemembers,
              diversity,
              completed: true,
            },
          },
        });
        navigate("/startupregistration/ownership");
        updateLatestActions(userRef, "Saved Founders form", isMobile);
      }, 2000);
    }
  };

  const {
    founders,
    //female,
    error,
    message,
    //loading,
    generatedFields,
    nonfounder,
    //femalemembers,
    diversity
  } = data;
  return (
    <div className="shadow-grey w-[90%]   rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className="flex items-end pl-3 md:pl-0">
        <div
          className={` h-10 md:h-16 flex items-center justify-center mr-2 md:mr-4 `}
        >
          <img className=" h-full  " src={FoundersLogo} alt="Company" />
        </div>
        <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
          Founders & Team
        </h1>
      </div>
      <form className=" " onSubmit={handleSubmit} >
        <div className="mt-5 px-3 md:pl-24">
          <h1>Company Founders</h1>
          <div className="flex mt-2  items-center bg-body py-2 pl-2">
            {foundersNumberSelectOptions.slice(1).map((numberFounder, index) => {
              return (
                <input
                  className={`uppercase mr-2 ${numberFounder === founders
                      ? "border-[#00b0f0]"
                      : "border-gray-500"
                    }  cursor-pointer w-12 h-8 border-2 rounded-full   text-white outline-none `}
                  key={"key" + index}
                  type="button"
                  value={numberFounder}
                  name="founders"
                  onClick={handleChange}
                ></input>
              );
            })}
          </div>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <div className="flex justify-between items-center my-2 ">
            <h1 className="mr-4">
              Founder Names, Location, Experience [recommended]
            </h1>

          </div>
          {/* // Dyanamic flex container */}
          {generatedFields.founder.map((field, index) => {
            return (
              <div
                className="flex items-start flex-col w-full justify-between "
                key={field.fieldName1 + index}
              >
                <div className="flex w-full">
                <input
                  className="border-blackish mt-2.5 w-4/5 mr-1.5 md:mr-2.5 flex  rounded border p-1.5 md:p-2.5 text-black outline-none"
                  type="text"
                  name={field.fieldName1}
                  placeholder={`Founder ${index + 1}`}
                  value={field.fieldValue1}
                  onChange={(e) =>
                    handleDynamicInput(e, "founder", index, "fieldValue1")
                  }
                ></input>
                {index===0 &&
                              <input
                              type="button"
                              className=" self-end  basis-1/12 uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                              style={{ width: 40, height: 40 }}
                              value="+"
                              onClick={() => addField("founder")}
                            ></input>
                }
                                {generatedFields.founder.length>1 && index>0 && <input
                  type="button"
                  className="self-end  basis-1/12 uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                  style={{ width: 40, height: 40 }}
                  value="-"
                  onClick={() => closeField("founder", index)}
                ></input>}
</div>
                <CountryDropdown
                  className="border-blackish mt-2.5 w-4/5 mr-1.5 md:mr-2.5 rounded border p-1.5 md:p-2.5 text-black outline-none"
                  value={field.fieldValue2}
                  onChange={(countryName) =>
                    handleCountryInput("founder", index, countryName)
                  }
                  name={field.fieldName2}
                />

                <input
                  className="border-blackish mt-2.5 w-4/5 mb-5 mr-1.5 md:mr-2.5 rounded border p-1.5 md:p-2.5 text-black outline-none"
                  type="text"
                  name={field.fieldName3}
                  placeholder={"Previous startup(s)"}
                  value={field.fieldValue3}
                  onChange={(e) =>
                    handleDynamicInput(e, "founder", index, "fieldValue3")
                  }
                ></input>



                
              </div>
            );
          })}
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <h1>Non-founder Team members</h1>
          <div className="flex mt-2 items-center bg-body py-2 pl-2">
            {foundersNumberSelectOptions.map((nonfounderMember, index) => {
              return (
                <input
                  className={`uppercase mr-2 ${nonfounderMember === nonfounder
                      ? "border-[#00b0f0]"
                      : "border-gray-500"
                    }  cursor-pointer w-12 h-8 border-2 rounded-full   text-white outline-none `}
                  key={"key" + index}
                  type="button"
                  value={nonfounderMember}
                  name="nonfounder"
                  onClick={handleChange}
                ></input>
              );
            })}
          </div>
        </div>

        <div className="mt-5 px-3 md:pl-24">
          <div className="flex justify-between items-center my-2 ">
            <h1 className="mr-4">
              Non-Founder Team members: Name, Location, Experience [optional]
            </h1>
          </div>
          {generatedFields.member.map((field, index) => {
            return (
              <div
                className="flex items-start flex-col w-full justify-between "
                key={field.fieldName1 + index}
              >
                <div className="flex w-full">
                <input
                  className="border-blackish mt-2.5 w-4/5 mr-1.5 md:mr-2.5 rounded border p-1.5 md:p-2.5 text-black outline-none"
                  type="text"
                  name={field.fieldName1}
                  placeholder={`Member ${index + 1}`}
                  value={field.fieldValue1}
                  onChange={(e) => handleDynamicInput(e, "member", index, "fieldValue1")}
                ></input>
                                {index===0 &&
                              <input
                              type="button"
                              className=" self-end  basis-1/12 uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                              style={{ width: 40, height: 40 }}
                              value="+"
                              onClick={() => addField("member")}
                            ></input>
                }
                {index>0 && <input
                  type="button"
                  className="self-end  basis-1/12 uppercase bg-navBg cursor-pointer rounded border border-gray-500 flex items-center justify-center w-10 h-10 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                  style={{ width: 40, height: 40 }}
                  value="-"
                  onClick={() => closeField("member", index)}
                ></input>}
                </div>
                <CountryDropdown
                  className="border-blackish mt-2.5 w-4/5 mr-1.5 md:mr-2.5 rounded border p-1.5 md:p-2.5 text-black outline-none"
                  value={field.fieldValue2}
                  onChange={(countryName) =>
                    handleCountryInput("member", index, countryName)
                  }
                  name={field.fieldName2}
                />
  
                <input
                  className="border-blackish mt-2.5 w-4/5 mr-1.5 mb-4  md:mr-2.5 rounded border p-1.5 md:p-2.5 text-black outline-none"
                  type="text"
                  name={field.fieldName3}
                  placeholder={"Previous startup(s)"}
                  value={field.fieldValue3}
                  onChange={(e) =>
                    handleDynamicInput(e, "member", index, "fieldValue3")
                  }
                ></input>


              </div>
            );
          })}
        </div>

        <div className="my-4 w-[90%]">
          <h1 className="  pl-3 md:pl-24  text-white ">
          Since some investors prefer companies with one or more Founders 
          represented in the following, feel free to select any that may apply [optional]:
          </h1>
          {diversitySelectOptions.map((selection) => {
            return (
              <div
                key={selection.value}
                className="mt-3 px-3 md:pl-24 flex items-center "
              >
                <Switch
                  onChange={() =>
                    setData({
                      ...data,
                      diversity: {
                        ...diversity,
                        [selection.value]: !diversity[selection.value],
                      },
                    })
                  }
                  checked={diversity[selection.value]}
                  id={selection.value}
                />
                <label htmlFor={selection.value} className="ml-5">
                  {selection.name}
                </label>
              </div>
            );
          })}
        </div>
        
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">Saving... <span className='text-silverPrairi'>(click progress bar if you wish to edit)</span></p>
        )}
        <div className="flex justify-center my-5 px-3 md:pl-24">
          <NextButton value="SAVE" />
        </div>
      </form>
    </div>
  );
};

export default Founders;