import React from "react"
import Consent from "../assets/consent.png"

const TwilioConsent = () => {
    return(
        <div>
            <img src={Consent}></img>
        </div>
    )
}

export default TwilioConsent