import React, { useContext } from "react";
import { navBarContext } from "./context/context";

const ForgotPasswordSent = () => {
  const { navBarHeight } = useContext(navBarContext);
  return (
    <div  id="forgotPw__"
    style={{
      marginTop: navBarHeight + 70,
      marginBottom: navBarHeight + 10,
      width: "100%",
      minWidth: "366px",
      padding: "2%",
    }}>
      <section
        className={`
        bg-gradient-to-b from-gray-800 to-gray-900
        shadow-black mx-auto
        w-11/12 md:max-w-lg
        rounded-md
        py-2.5 px-5
        shadow-md
      `}
    >
      <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
        Thank You
      </h1>
          <div className="mt-5" style={{textAlign:"center", marginBottom:"20px"}}>
            <p>
             Please check your email and follow instruction.
            </p>          
          </div> 
    </section>
    </div>
  );
};

export default ForgotPasswordSent;
