import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import BackgroundImg from "../assets/ad-blank-wide.jpg"
import Logo from "../assets/prairiwhite.png"
import { Modal, PrairiButton } from "@peterfosso/prairi-components";
import { navBarContext} from "./context/context";
import Phone from "../assets/matchphone.png"

const AdLanding = () => {
  let navigate = useNavigate();

  const { navBarHeight } = useContext(navBarContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



    return (<div>
{!isMobile &&
<div className="bg-adLanding bg-cover bg-center w-screen h-screen ">
  <img src={BackgroundImg} className="w-screen h-screen object-cover" alt="Background" />
  <div className="absolute text-black bottom-1/4 left-1/2 w-1/2"> 
    <div className="text-[54px] font-bold m-0">Find the right investors</div> 
    <div className=" text-[40px] mb-4 -mt-2">(without even looking for them)</div> {/* Added subtitle */}
    <div className="w-[80%] text-lg">PRAIRI matches you with investors, around the world, so that you can instead work on growing your company.</div>
    <ul className="pl-12 list-disc mt-4 text-lg">
        <li className="mb-2 ">Investors view your profile and then <span className="font-bold mb-2">they come to you</span></li>
        <li className="underline mb-2">No searching</li>
        <li>Match with investors who are looking for a startup like yours</li>
    </ul>
    <div className="mt-4 text-lg"><span className="text-redPrairi font-bold">Special Offer:</span> Join now and get a <span className="font-bold">lifetime membership</span> for just a one-time fee of $100 (USD).</div>
    <button className="bg-blue-500 text-white font-bold py-2 px-8 mt-4 ml-[170px] mb-8 rounded" onClick={()=>navigate("/invitation/stripe")}>
      Save me time and find me investors!
    </button>
  </div>
  <div className="text-[10px] text-silverPrairi absolute bottom-[10%] left-[45%] w-1/2">
  *NOTE: How many matches you receive depends on your startup’s criteria. Lifetime membership strictly refers to our service of matching you, as a startup, to investors, at the time(s) you are seeking funding. PRAIRI reserves the right to expand its services and offerings at any time in the future and to charge for them accordingly (but you, of course, won’t be required to purchase them).
  </div>
</div>}
{isMobile &&         <div className="h-screen w-[98%] ">
        <div className="bg-[white] -mb-2 text-black bg-cover flex flex-col items-center bg-center   w-full "     style={{
            marginTop: navBarHeight ,

            width: "100%",
            minWidth: "366px",
            padding: "2%",
          }}>
            <div className="text-[38px] text-center font-bold m-0">Find the right investors</div> 
            <div className=" text-[25px] mb-4 -mt-2">(without even looking for them)</div> {/* Added subtitle */}
            <div className="w-[80%] text-xl text-justify ">PRAIRI matches you with investors, around the world, so that you can instead work on growing your company.</div>
            <ul className="list-disc mt-4 text-lg w-[80%] pl-4 text-xl">
                <li className="mb-2 ">Investors view your profile and then <span className="font-bold mb-2">they come to you</span></li>
                <li className="underline mb-2">No searching</li>
                <li>Match with investors who are looking for a startup like yours</li>
            </ul>
            <div className="mt-4 text-2xl"><span className="text-redPrairi font-bold">Special Offer:</span> Join now and get a <span className="font-bold">lifetime membership</span> for just a one-time fee of $100 (USD).</div>
            <button className="bg-blue-500 text-white font-bold py-2 px-8 my-8 h-[50px] mb-8 rounded" onClick={()=>navigate("/invitation/stripe")}>
              Save me time and find me investors!
            </button>
<img src={Phone} className="h-[20%]"></img>
</div>
          <div className="text-[10px] bg-[#262626] w-full px-12   flex items-center justify-center text-silverPrairi  ">
          *NOTE: How many matches you receive depends on your startup’s criteria. Lifetime membership strictly refers to our service of matching you, as a startup, to investors, at the time(s) you are seeking funding. PRAIRI reserves the right to expand its services and offerings at any time in the future and to charge for them accordingly (but you, of course, won’t be required to purchase them).
          </div>
          </div>}
    </div>
          );
};

export default AdLanding;