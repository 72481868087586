import FilterStrategy from './FilterStrategy.js';
import { ERR_INVALID_INVESTOR, ERR_INVALID_STARTUP } from './util/error_messages.mjs';
import { fetchExchangeRates } from '../utils.js';

class AmountFilter extends FilterStrategy {
    doFilter(investor, startups) {
        this.guard(investor, startups); // Move the guard clause to the top of the function

        //console.log('Filtering on Amount');
        //console.log('investor amount: ' + JSON.stringify(investor.formData.industry.amount));

        const rates = {
          USD: {
            EUR: 1.01, // USD strengthened against EUR
            SEK: 0.087, // USD strengthened against SEK
            GBP: 1.20, // USD strengthened against GBP
          },
          EUR: {
            USD: 0.92, // EUR weakened against USD
            SEK: 0.083, // EUR weakened against SEK
            GBP: 1.08, // EUR weakened against GBP
          },
          SEK: {
            USD: 0.087, // No change for SEK to USD
            EUR: 0.083, // No change for SEK to EUR
            GBP: 0.079, // SEK slightly strengthened against GBP
          },
          GBP: {
            USD: 0.79, // GBP significantly weakened against USD
            EUR: 0.89, // GBP significantly weakened against EUR
            SEK: 11.80, // GBP slightly weakened against SEK
          },
        };
        
         

        const investorCurrency = investor.formData.industry.currency;
        const investorMinAmount = investor.formData.industry.amount[0] * 0.8;
        let investorMaxAmount;
        if (investor.formData.industry.amount[1]===10000000 && (investor.formData.industry.currency==="USD" || investor.formData.industry.currency==="GBP" || investor.formData.industry.currency==="EUR")){
          investorMaxAmount = -1
        }
        if (investor.formData.industry.amount[1]===100000000 && investor.formData.industry.currency==="SEK"){
          investorMaxAmount = -1
        }
        else{
          investorMaxAmount = investor.formData.industry.amount[1] * 1.2;
        }


        const filteredData = startups.filter((startup) => {
            const startupCurrency = startup.formData.industry.currency;
            const conversionFactor = (startupCurrency === investorCurrency) ? 1.0 : rates[startupCurrency][investorCurrency];
            const startupAmount = startup.formData.industry.amount * conversionFactor;

            // Check if the startup amount is within the range defined by the investor
            return startupAmount >= investorMinAmount && (investorMaxAmount === -1 || startupAmount <= investorMaxAmount);
        });

        return filteredData;
    }
}

export default AmountFilter;