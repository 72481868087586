import FilterStrategy from './FilterStrategy.js';
import {ERR_INVALID_INVESTOR, ERR_INVALID_STARTUP} from './util/error_messages.mjs';
class IndustryFilter extends FilterStrategy {
    doFilter(investor, startups) {
        let industries = new Set(Object.keys(investor.formData.industry.industry).filter((key) => investor.formData.industry.industry[key]))

        // console.log('Filtering on Industry');
        // console.log('industries: ' + industries)
        this.guard(investor, startups);
        let filteredData = startups.filter(startup => industries.has(startup.formData.industry.industry));
        if (filteredData.length===0){
            filteredData = startups.filter(startup => industries.has(startup.formData.industry.industry2))
        }
        return filteredData;
    }
}

export default IndustryFilter;