import React, { useEffect, useRef } from "react";
import { useOnScreen } from "../common/useOnScreen";
import { useSpring, animated, config } from "react-spring";
const TransitionUpwardElement = ({
  children,
  elementType,
  elementClassNames,
  ...others
}) => {
  const elementRef = useRef();
  const isOnScreen = useOnScreen(elementRef);
  const elementDelay = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const [styles, api] = useSpring(() => ({
    y: 40,
    opacity: 0,
    delay: () => elementDelay(100, 300),
    config: { duration: 1000, config: config.molasses },
  }));
  useEffect(() => {
    if (isOnScreen) {
      api.start({ y: 0, opacity: 1 });
    }
  }, [api, isOnScreen]);
  let renderedElement;
  switch (elementType) {
    case "h1":
      renderedElement = (
        <animated.h1
          ref={elementRef}
          style={styles}
          className={elementClassNames}
        >
          {children}
        </animated.h1>
      );
      break;
    case "img":
      renderedElement = (
        <animated.div
          ref={elementRef}
          style={styles}
          className={elementClassNames}
        >
          {children}
        </animated.div>
      );
      break;
    case "p":
      renderedElement = (
        <animated.p
          ref={elementRef}
          style={styles}
          className={elementClassNames}
        >
          {children}
        </animated.p>
      );
      break;
    case "button":
      renderedElement = (
        <animated.button
          ref={elementRef}
          style={styles}
          className={elementClassNames}
          {...others}
        >
          {children}
        </animated.button>
      );
      break;
    case "li":
      renderedElement = (
        <animated.li
          ref={elementRef}
          style={styles}
          className={elementClassNames}
        >
          {children}
        </animated.li>
      );
      break;

    default:
      break;
  }
  return <>{renderedElement}</>;
};

export default TransitionUpwardElement;
