import React, { useContext } from "react";
import { dataContext } from "../context/context";
import IndustryLogo from "../../assets/industry.png";
import FoundersLogo from "../../assets/founders.png";
import OwnershipLogo from "../../assets/ownership.png";
import SupportLogo from "../../assets/support.png";
const BreadcrumbMiddle = (props) => {
  const { globalData } = useContext(dataContext);
  const { currentLocation, formData } = globalData;
  let parentDivCrumb = "bg-gray-700";
  let rectangleDivCrumb = "bg-gray-700";
  let triangleDivBorder = "border-l-gray-700";
  let mobileBottomBorder = "border-b-gray-700";
  let opacity = "opacity-50";
  let title = props.title.toLowerCase();
  let renderedElement;
  let roundedRightRectangle;
  if (currentLocation === title) {
    parentDivCrumb = "bg-gray-500";
    rectangleDivCrumb = "bg-gray-500";
    triangleDivBorder = "border-l-gray-500";
    mobileBottomBorder = "border-b-blue-500";
    opacity = "opacity-100";
  }
  if (currentLocation !== title && formData[title].completed) {
    parentDivCrumb = "bg-green-700";
    rectangleDivCrumb = "bg-green-700";
    triangleDivBorder = "border-l-green-700";
    mobileBottomBorder = "border-b-green-500";
    opacity = "opacity-70";
  }
  switch (title) {
    case "industry":
      renderedElement = IndustryLogo;
      break;
    case "founders":
      renderedElement = FoundersLogo;
      break;
    case "ownership":
      renderedElement = OwnershipLogo;
      break;
    case "support":
      renderedElement = SupportLogo;
      break;
    default:
      break;
  }
  return (
    <>
      {/* mobile */}
      <div
        className={`md:hidden pb-2 border-b-2  h-10 flex items-center justify-center ${mobileBottomBorder} ${opacity}`}
      >
        <img className="h-full" src={renderedElement} alt="icon" />
      </div>
      {/* Bigger devices */}
      <div
        className={` hidden  md:flex items-center ${parentDivCrumb} ${opacity} text-white`}
      >
        <div className="w-0 h-0 border-t-custom border-b-custom border-l-custom border-y-transparent border-l-customBorder border-solid"></div>
        <div className="flex items-center bg-body">
          <div
            className={`flex items-center h-10 py-0 px-2.5 text-sm  ${rectangleDivCrumb} ${roundedRightRectangle} `}
          >
            {props.displayTitle}
          </div>
          <div
            className={`w-0 h-0 border-t-custom border-b-custom border-l-custom border-y-transparent ${triangleDivBorder} border-solid`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default BreadcrumbMiddle;
